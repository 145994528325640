.contactInfo {
    position: relative;
    background-color: #33351e;
    width: 100%;
    color: white;
    border-bottom: 1px solid $borderColor;

    @include media-min($md){
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
    

    &__box {
        z-index: 10;
        min-height: rem(220px);
        position: absolute;
        top: -30%;
        display: grid;

        width: 88%;


        @include media-min($xsm) {
            width: 65%;
        }

        @include media-min($sm){
            grid-template-columns: repeat(4, 1fr);
            padding-left: rem(70px);
            padding-right: rem(70px);
            min-height: rem(122px);

        }

        @include media-min($md){
            padding: 0;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(5, 1fr);

        }

        img {
            transform: translateX(-50%);
            position: absolute;
            top: 0;
            grid-column: 2 / 2;
            z-index: 1;

            max-height: rem(99px);

            @include media-min($sm){
                grid-column: 1 / 4;
            }

            @include media-min($md){
                grid-column: 2 / 4;
                height: rem(126px);
            }

            @include media-min($xl) {
                height: rem(100px);
            }
        }

        div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;

            background-color: #505331;
            grid-column: 2 / 4;
            width: initial;
            margin: 0;
            padding-left: 3rem;
            padding-bottom: 1rem;

            @include media-max($sm){
                padding-bottom: rem(80px);
            }

            @include media-min($sm){
                grid-column: 1 / 5;
            }

            @include media-min($md){
                // grid-column: 2 / 4;
                grid-column: 2 / 5;
                margin-right: 100px;
            }

            @include media-min($lg){
                grid-column: 2 / 4;
                margin-right: 0;
            }
        }

        div h1 {
            font-family: 'montserrat';
            font-size: rem(37px);
            font-weight: 700;
            line-height: rem(44px);
            text-transform: uppercase;
        }
    }

    &__cnt {
        display: grid;
        position: relative;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
            "text topInfo"
            "text bottomInfo";

        grid-column: 2 / 4;
        width: initial;
        max-height: rem(350px);
        margin: 0;
        color: white;

        @include media-max($sm){
            grid-template-columns: 1fr;
            grid-template-rows: 1fr rem(150px) rem(100px);
            grid-template-areas:
                "text"
                "topInfo"
                "bottomInfo";
            max-height: rem(560px);
        }

        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 1px;
            height: 100%;
            background: $borderColor;

            @include media-max($sm) {
                left: 2.8125rem;
            }

            @include media-min($sm){
                left: rem(70px);
                height: rem(315px);

            }

            @include media-min($md) {
                height: 100%;
                left: 0;
            }
        }
    }

    &__arrow {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: rem(100px);

        @include media-max($sm){
            display: none;
        }

        @include media-min($sm){
            display: none;
        }

        @include media-min($md){
            display: flex;
            grid-column-start: 5;
        }

        @include media-min($lg){
            grid-column-start: 4;
        }

        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            right: 0;
            width: 1px;
            height: rem(60px);
            background: #60614f;

            @include media-min($sm) {
                right: rem(385px);
            }

            @include media-min($md){
                width: 0;
            }

            @include media-min($lg) {
                width: 1px;
                right: rem(385px);
            }
        }

        img {
            animation: mover 1s infinite alternate;
            height: rem(21px);
        }
    }

    &__text {
        padding: 6rem 1rem 1rem 3rem;
        grid-area: text;

        @include media-max($sm){
            padding-left: rem(70px);
        }

        @include media-min($sm){
            padding: 6rem 1rem 1rem 6rem;
        }

        @include media-min($md){
            padding: 6rem 1rem 1rem 3rem;
        }

        ul {
            list-style: none;
            padding: 0;
        }

        h3 {
            font-weight: 600;
        }
    }

    &__topInfo {
        display: flex;
        flex-direction: column;
        padding: 6rem 0rem 0rem 2rem;
        grid-area: topInfo;

        @include media-max($sm){
            padding: rem(10px 0 0 70px);
        }

        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: rem(200px);
            width: 1px;
            height: rem(110px);
            background: #60614f;

            @include media-max($sm) {
                right: rem(375px);
                display: none;
            }

            @include media-min($sm){
                right: rem(67px);
            }

            @include media-min($md){
                right: rem(220px);
            }

            @include media-min($lg) {
                right: rem(400px);
            }
        }

        &::after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            width: 1px;
            height: rem(110px);
            background: #60614f;

            @include media-min($sm) {
                width: 0;
            }

            @include media-min($md){
                width: rem(1px);
                right: rem(-110px);
            }

            @include media-min($lg) {
                right: 0;
            }
        }

        & > div {
            display: flex;
            flex-direction: row;
            
            & > div {
                margin-left: rem(30px);
                margin-bottom: rem(10px);
            }
    
            & >  div h4 {
                margin: 0;
            }
    
            & >  div a {
                font-weight: bold;
                font-size: rem(18px);
                letter-spacing: rem(1px);
            }
    
            & >  div a:hover {
                color: #f16322
            }
    
            & > img {
                max-height: rem(38px);
            }
        }
    }

    &__bottomInfo {
        grid-area: bottomInfo;
        display: flex;
        flex-direction: row;
        padding: 3rem 0rem 0rem 2rem;

        @include media-max($sm){
            padding: rem(10px 0 0 70px);
        }

        @include media-min($sm){
            padding-right: rem(140px);
        }

        @include media-min($md){
            padding-right: 0;
        }

        &::before {
            position: absolute;
            content: "";
            bottom: 0;
            right: rem(400px);
            width: 1px;
            height: rem(60px);
            background: #60614f;

            @include media-max($sm) {
                width: 0;
            }

            @include media-min($md){
                right: rem(220px);
            }

            @include media-min($lg) {
                right: rem(400px);
            }
        }

        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            right: 0;
            width: 1px;
            height: rem(60px);
            background: #60614f;

            @include media-max($sm) {
                width: 0;
            }

            @include media-min($sm){
                right: rem(67px);
                width: rem(1px);
            }

            @include media-min($md){
                right: rem(-110px);
            }

            @include media-min($lg) {
                right: 0;
            }
        }

        div {
            margin-left: rem(30px);
        }

        div > h4 {
            font-weight: 100;
            margin: 0;
        }

        div > a {
            font-weight: bold;
            font-size: rem(18px);
            letter-spacing: rem(1px);
        }

        div > a:hover {
            color: #f16322
        }

        img {
            max-height: rem(28px);
            // height: fit-content;
        }
    }
}