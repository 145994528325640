.fourfour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-top: rem(50px);
  .uk-container {
    max-width: rem(1000px);
  }
  &__logo {
    height: 3.25rem;
  }
  &__wrapper {
    font-size: rem(24px);
    color: white;
    > * {
      margin-bottom: rem(30px);
    }
    %headings {
      padding-bottom: 0;
    }
    h2 {
      font-size: rem(200px);
      line-height: rem(200px);
      margin-bottom: rem(50px);
      font-weight: 700;
      @include media-min($sm) {
        font-size: rem(280px);
        line-height: rem(280px);
      }
    }
    h3 {
      font-size: rem(32px);
      font-weight: 700;
    }
    a {
      font-size: rem(32px);
      font-weight: 700;
    }
  }
}
