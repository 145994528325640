.solutions {
  background: #33351e;
  color: $white;
  position: relative;

  @include media-min($md) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    min-height: 30vw;
  }

  &__title {
    position: absolute;
    width: 90%;
    display: inline-block;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    background: #505331;
    padding: rem(12px 26px);
    z-index: 7;

    height: rem(120px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: rem(0px);
    padding-bottom: rem(29px);
    padding-top: rem(29px);

    @include media-min($md) {
      grid-column: 2 / 4;
      width: initial;
      margin: 0;
    }

    h3,
    p {
      padding: 0;
      margin: 0;
    }

    // span {
    //   display: block;
    //   margin: rem(18px 0);
    // }

    // img {
    //   max-width: rem(25px);
    //   animation: mover 1s infinite alternate;
    // }

    h3 {
      font-size: rem(22px);
      font-weight: 700;
    }

    p {
      font-weight: 400;
    }
  }

  &__cnt {
    display: inline-block;

    @include media-min($md) {
      grid-column: 2 / 4;
      padding: rem(0 68px);
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      // height: 100%;
      height: 106%;
      background: $borderColor;
    }

    &::before {
      // top: 0;
      top: 20%;
      left: rem(42px);

      @include media-min($sm) {
        left: rem(58px);
      }

      @include media-min($md) {
        height: 30%;
        left: 20%;
      }
    }

    &::after {
      // top: 60%;
      top: 21%;
      right: rem(42px);

      @include media-min($md) {
        top: 0;
        right: 20%;
        z-index: 5;
      }
    }
  }

  &__txt {
    margin: 0 auto;
    max-width: 70%;
    margin-bottom: rem(40px);
    padding: rem(200px 0 80px 0);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    @include media-min($md) {
      margin: 0;
      max-width: 100%;
    }

    p {
      font-size: rem(14px);
      position: relative;

      @include media-min($md) {
        font-size: rem(19px);
        font-weight: 300;
      }
    }

    img {
      display: none;

      @include media-min($md) {
        display: block;
        position: absolute;
        left: -4.25rem;
        transform: translateX(-50%);
        height: rem(86px);
        top: 12.5rem;
      }
    }

    span {
      font-size: rem(12px);
      font-weight: 300;
      color: $white;

      @include media-min($md) {
        font-size: rem(16px);
      }
    }
  }

  &__quote {
    position: absolute;
    margin: 0;
    bottom: 0;
    right: rem(20px);
    font-size: rem(12px);
    font-weight: 600;
    background: #1d201b;
    color: $white;
    max-width: rem(140px);
    padding: rem(20px 13px);
    transform: translateY(50%);
    font-style: normal !important;

    @include media-min($md) {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      grid-column: 4 / 6;
      line-height: rem(25px);
      max-width: 100%;
      margin-left: rem(40px);
      padding-bottom: rem(120px);
      font-size: rem(19px);
      transform: translateY(0);
      background: transparent;
    }

    @include media-min($md) {
      &--txt {
        display: block;
        margin-bottom: rem(10px);
        z-index: 3;
      }
    }

    &--lines {
      display: none;
    }

    @include media-min($md) {
      &--lines {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;

        span {
          position: absolute;
          display: block;
          height: 87%;
          width: rem(180px);
          bottom: 0;
        }

        span:first-of-type {
          border: 1px solid $borderColor;
        }

        span:nth-of-type(2) {
          border: 1px solid $borderColor;
          border-bottom: 3px solid #33351e;
          background-color: #33351e;
          transform: rotate(90deg) translate(-50%, -74%);
          height: 63%;

          @include media-min-max(1023px , 1100px) {
            transform: rotate(90deg) translate(-50%, -73%);
          }
        }

        span:last-of-type {
          background: $borderColor;
          width: rem(1px);
          height: 84%;
          right: 0;
          z-index: 3;

          &::after{
            content: "";
            position: absolute;
            width: rem(1px);
            height: 77%;
            bottom: 100%;
            background: $borderColor;
            transform: rotate(-76deg);
            transform-origin: bottom left;
          }
        }
      }
    }
  }

  &__aboutBtn {
    display: none;

    &--link{
      @include media-max($sm) {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        height: rem(80px);
  
        a {
          display: block;
          background: #505331;
          height: rem(70px);
          width: rem(250px);
          line-height: rem(67px);
          text-align: right;
          padding: rem(0 40px);
          font-size: rem(17px);
          font-weight: 700;
          position: relative;
          color: $white;
          transition: 0.8s;
          margin-left: rem(19px);
          z-index: 2;
  
          &::before {
            content: "";
            position: absolute;
            left: rem(40px);
            margin: auto 0;
            top: 0;
            bottom: 0;
            width: 25%;
            background-color: $white;
            height: 3px;
          }
  
          &:hover {
            background-color: $secondary;
            color: $white;
          }
        }
  
        span {
          position: absolute;
          border: 1px solid $borderColor;
          width: calc(100% - 16px);
          top: 0;
          right: 0;
          height: rem(180px);
          margin-left: rem(19px);
          z-index: 1;
        }
      }
    }

    &--small{
      a{
        height: rem(46px) !important;
        line-height: rem(43px) !important;
        padding: rem(0 23px) !important;
        font-size: rem(14px) !important;
        margin-left: rem(36px) !important;
        margin-right: rem(53px) !important;

        &::before {
          left: rem(19px) !important;
          width: 25% !important;
          height: 2px !important;
        }
      }
    }

    @include media-min($md) {
      display: block;
      grid-row: 1;
      grid-column: 4 / 5;
      // position: relative;

      position: absolute;
      width: rem(382px);
      bottom: 0;

      a {
        display: block;
        background: #505331;
        height: rem(92px);
        line-height: rem(92px);
        text-align: right;
        padding: rem(0 40px);
        font-size: rem(17px);
        font-weight: 700;
        position: relative;
        color: $white;
        transition: 0.8s;
        margin-left: rem(19px);
        z-index: 2;

        &::before {
          content: "";
          position: absolute;
          left: rem(40px);
          margin: auto 0;
          top: 0;
          bottom: 0;
          width: 25%;
          background-color: $white;
          height: 3px;
        }

        &:hover {
          background-color: $secondary;
          color: $white;
        }
      }

      span {
        position: absolute;
        border: 1px solid $borderColor;
        width: calc(100% - 16px);
        top: 0;
        right: 0;
        height: rem(180px);
        margin-left: rem(19px);
        z-index: 1;
      }
    }

    @include media-min($xl) {
      span {
        width: calc(100% - 19px);
      }
    }

    @include media-min(2800px) {
      span {
        width: calc(100% - 40px);
      }
    }
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
