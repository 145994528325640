.header {
  background-color: #1e1f1167;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  color: $white;
  transition: background-color 0.3s;

  &-noneBg {
    background-color: unset;
  }

  &.sticky {
    background-color: #1e1f11;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0s;
  }

  &.sticky-main {
    background-color: #505331;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: rem(85px);
    position: relative;
    border-bottom: 1px solid $borderColor;

    @include media-min($lg) {
      height: rem(122px);
      display: grid;
      grid-template-columns: 1fr 4fr;
      grid-template-rows: 100%;
      min-width: 100%;
      border-bottom: none;
    }
  }

  &__logo {
    position: absolute;
    left: rem(15px);
    display: block;
    height: 100%;
    max-width: 40%;
    display: flex;
    align-items: center;
    user-select: none;
    z-index: 5;

    img {
      width: 100%;

      @include media-min($md) {
        width: 65%;
      }

      @include media-min($lg) {
        width: 100%;
      }
    }

    @include media-min($lg) {
      position: initial;
      max-width: 100%;

      img {
        margin: 0 auto;
        max-width: 70%;
      }
    }
  }

  &__cnt {
    position: absolute;
    width: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;
    background: linear-gradient(180deg,
        rgba(241, 99, 34, 1) 34%,
        rgba(171, 70, 23, 1) 94%);
    z-index: 4;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 20%;
    padding-top: rem(110px);

    @include media-min($lg) {
      position: initial;
      background: transparent;
      display: grid !important;
      grid-template-columns: 3fr 1fr;
      padding: 0;
      width: auto;
      min-height: 100%;
      border-left: 1px solid #60614f;
    }

    &-bg {
      background-color: #1e1f11;
    }
  }

  &-nav {

    &--desktop {
      display: none;
    }

    @include media-min($lg) {
      &--mobile {
        display: none;
      }

      &--desktop {
        margin: 0;
        display: block;
        border-bottom: 1px solid $borderColor;
      }
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;

      @include media-min($lg) {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        flex-direction: row;
        height: 100%;
        align-items: center;

        border-right: 1px solid $borderColor;
      }
    }

    &__item {
      &:first-of-type {
        display: flex;
        flex-direction: column;

        position: relative;
      }

      &--mobile {
        &>div:first-of-type {
          margin-top: rem(15px);
        }
      }

      &>.uk-accordion> :nth-child(n + 2) {
        margin-top: 0 !important;
      }

      .uk-dropdown {
        box-shadow: 0px 27px 22px 1px rgba(0, 0, 0, 0.21) !important;
      }

      @include media-min($lg) {
        height: 100%;
        transition: 0.8s;
        min-width: 23%;

        &:hover {
          background-color: $secondaryLight;

          &>button {
            color: white;
          }
        }

        &:first-of-type():hover {
          .header__arrowmenu {
            opacity: 1;
            transition: 0.5s ease-in-out;
            bottom: rem(22px);
          }
        }
      }
    }

    &__link {
      position: relative;
      outline: none;
      border: none;
      background: none;
      font-weight: 700;
      text-transform: uppercase;
      font-size: rem(24px);
      margin: 0;

      &:hover {
        text-decoration: underline;
      }

      &-active {
        color: $secondary;
      }

      &>span {
        display: block;
        margin: 0 auto;
      }

      &:focus {
        color: white
      }

      @include media-min($lg) {
        display: flex;
        height: 100%;
        align-items: center;
        padding: rem(0 30px);
        font-size: rem(16px);
        font-weight: 700;

        &:hover {
          text-decoration: initial;
          color: white;
        }
      }
    }

    button {
      padding: 0;
      text-align: left;

      @include media-min($lg) {
        height: 100%;
        position: relative;
        z-index: 8;
      }
    }

    .uk-accordion-title {
      color: $white;
      overflow: visible;

      &::before {
        display: none;
      }
    }
  }

  &-subnav {
    padding: 0;
    list-style: none;
    z-index: 6;
    margin: 0;

    background-color: transparent;

    @include media-min($lg) {
      width: calc(60vw - 8px);
    }

    &__cnt {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      background-color: #F16322;
    }

    &__elem {
      grid-column: 1 / 3;
    }

    &__desc {
      grid-column: 3 / 7;
      background-color: #AB4617;

      &-cnt {
        display: flex;
        flex-wrap: wrap;
        padding: rem(34px 38px 14px 38px);

        &>a {
          flex-basis: 20.89rem;
          height: rem(156px);
          margin-bottom: rem(19px);
          position: relative;

          &:hover {
            .header-subnav__desc-overlay {
              opacity: 1;
              transition: 0.5s ease-in-out;
            }
          }

          &>img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &>a:nth-child(odd) {
          margin-right: rem(18px);
        }
      }

      &-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.315);
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin: 0;

      @include media-min($lg) {
        color: #FFFFFF;
      }
    }

    &__item {
      margin-top: 0;
      width: 80%;

      @include media-min($lg) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        height: rem(61px);

        border-bottom: 1px;
        border-bottom-style: solid;
        border-color: #FFAF8A;

        // &:not(:last-of-type)::after {
        //   content: "";
        //   position: absolute;
        //   width: 1px;
        //   margin: auto 0;
        //   right: 0;
        //   background: #FFAF8A;
        //   opacity: 0.3;
        //   bottom: 0;
        //   height: 100%;
        // }
      }

      &.uk-active {
        background-color: #AB4617;
        transition: 0.8s;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      width: 100%;
      font-weight: 700;
      text-transform: uppercase;
      font-size: rem(24px);
      position: relative;
      white-space: pre-line;
      color: $primaryDark !important;


      @include media-min($lg) {
        display: flex !important;
        font-size: rem(15px);
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow-wrap: break-word;
        padding: rem(0 27px);
        min-height: 100%;
        color: white !important;

        span {
          display: block;
          white-space: normal;
        }
      }

      @include media-min($xl) {
        margin-bottom: rem(20px);
      }

      &:hover {
        text-decoration: underline;

        @include media-min($lg) {
          text-decoration: initial;
          color: $white;
        }
      }

      &:focus {
        color: white;
      }

      &-ico svg {
        position: absolute;
        fill: #2a2c17;
        font-size: rem(14px);
        height: rem(24px);

        @include media-min($lg) {
          fill: white;
          position: initial;
          width: rem(26px);
          font-size: rem(14px);
          margin-right: rem(20px);
        }

        &:before {
          font-weight: bolder;
          color: $primaryDark !important;
        }

        &>path {
          fill: inherit !important;
        }
      }
    }

    &>img {
      position: absolute;
      right: 105%;
      max-height: rem(16px);
      margin-right: 0;

      @include media-min($lg) {
        position: initial;
        margin-bottom: rem(30px);
        max-height: rem(21px);
      }
    }

    i {
      position: absolute;

      font-size: rem(14px);

      @include media-min($lg) {
        position: initial;
        margin-bottom: rem(14px);
        max-height: rem(21px);
        font-size: rem(14px);
      }

      &:before {
        font-weight: bolder;
        color: $primaryDark !important;
      }
    }

    .uk-accordion-title::before {
      display: none;
    }

    &__droplist {
      list-style: none;
      margin: rem(20px 0);
      padding: 0;
      height: 100%;

      @include media-min($lg) {
        overflow-wrap: break-word;
      }
    }

    &__dropitem {
      font-weight: 400;
      margin-bottom: rem(15px);
      font-size: rem(18px);

      @include media-min($lg) {
        margin-bottom: 0;
        white-space: normal;
        padding: (5px 0);
        padding-left: rem(18px)
      }

      &:hover {

        @include media-min($lg) {
          cursor: pointer;
        }

      }
    }

    &__droplink {
      text-transform: uppercase;
      border-bottom: 1px solid transparent;

      @include media-min($lg) {
        color: $white;
        font-size: rem(13px);
      }

      &:hover {
        @include media-min($lg) {
          color: $white;
        }

        border-bottom: 1px solid $menuDark;
      }
    }
  }

  &__iconcnt {
    position: absolute;
    left: rem(-46px);
    max-width: rem(31px);
    display: flex;
    justify-content: flex-end;

    &>svg {
      width: 100%;
    }

    &>svg>path {
      fill: black;
    }
  }

  &__arrowmenu {
    opacity: 0;
    width: 17%;
    position: absolute;
    left: 41%;
    bottom: rem(35px);
  }

  &-login {
    margin-bottom: rem(10px);
    margin-top: rem(20px);

    @include media-min($lg) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
      border-right: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
    }

    &__link {
      font-weight: 700;
      text-transform: uppercase;
      font-size: rem(22px);
      transition: 0.8s;

      @include media-min($lg) {
        display: flex;
        height: 100%;
        align-items: center;
        padding: rem(0 30px);
        font-size: rem(17px);
      }

      i {
        margin-right: rem(10px);
      }

      &:hover {
        color: $secondaryDark;

        @include media-min($lg) {
          color: $white;
          background-color: $secondary;
        }
      }
    }
  }

  &-social {
    display: flex;
    list-style: none;
    padding: 0;
    margin-top: rem(20px);

    @include media-min($lg) {
      justify-content: flex-start;
      padding-left: rem(43px);
    }

    &__item {
      margin-top: rem(20px);

      &:not(:first-child) {
        padding-left: rem(22px);
      }
    }

    &__link {
      font-weight: 700;
      text-transform: uppercase;
      font-size: rem(22px);

      &:hover {
        color: $secondaryDark;

        @include media-min($lg) {
          color: $secondary;
        }
      }
    }
  }

  &__hamburger {
    display: block;
    border: none;
    position: relative;
    background-color: transparent;
    margin: rem(10px 20px 10px auto);
    width: rem(30px);
    height: rem(23px);
    cursor: pointer;
    z-index: 5;

    @include media-min($sm) {
      margin-right: rem(30px);
    }

    @include hover {
      outline: unset;
    }

    @include media-min($lg) {
      display: none;
    }

    span {
      display: block;
      position: absolute;
      height: rem(4px);
      width: 100%;
      background: $white;
      border-radius: rem(5px);
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      pointer-events: none;

      &:nth-child(1) {
        top: 0;
        will-change: top, width, left;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: rem(9px);
        will-change: transform;
      }

      &:nth-child(4) {
        top: rem(18px);
        will-change: top, width, left;
      }
    }

    &--open {
      span {
        &:nth-child(1) {
          top: rem(9px);
          width: 0%;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: rem(9px);
          width: 0%;
          left: 50%;
        }
      }
    }
  }

  &__droplist {
    &-cnt {
      position: absolute;
      z-index: 6;
      top: rem(0px) !important;
      width: 100%;
      height: 100%;
      margin-top: 0 !important;
      background-color: $menuDark;

      @include media-min($md) {
        left: 100% !important;
        height: 100%;
      }
    }

    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__dropcnt {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: rem(8px);
  }

  &__linkbox {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__droptext {
    position: absolute;
    left: rem(28px);
    bottom: rem(18px);
    color: white;
    font-weight: 700;
  }

  &__dropimg {
    min-width: 100%;
    height: auto;
    object-fit: cover;
    border: 1px solid white;
    margin-top: 17%;
  }
}