//.wrapper,
//.wrapper-medium {
//  max-width: $wrapper;
//  margin-left: auto;
//  margin-right: auto;
//  padding-left: $wrapper-padding;
//  padding-right: $wrapper-padding;
//  @include media-max($breakpoint-medium) {
//    padding-left: $wrapper-padding-medium;
//    padding-right: $wrapper-padding-medium;
//  }
//  @include media-max($breakpoint-small) {
//    padding-left: $wrapper-padding-small;
//    padding-right: $wrapper-padding-small;
//  }
//  &.wrapper-medium {
//    max-width: $wrapper-medium;
//  }
//}
//.list-clear {
//  @include list-clear();
//}
//.input-clear {
//  @include input-clear();
//}
//.text-ellipsis {
//  @include text-ellipsis();
//}
.b-cover {
  background: {
    position: center;
    repeat: no-repeat;;
    size: cover;
  };
}
.c-flex {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.b-cover-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -5;
  img {
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
    //max-width: initial;
  }
  picture {
    height: 100%;
    width: 100%;;
  }
}
