.caseStudies {
    background: #33351e;
    color: $white;

    @include media-min($md) {
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: rem(40px) auto;

        &-biggerRow {
            border-top: 1px solid #60614f;
            grid-template-rows: rem(160px) auto;
        }
    }

    &__logo {
        display: none;

        @include media-min($md){
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            display: flex;
            justify-content: flex-end;
            right: rem(-164px);
            padding-top: rem(60px);
    
            &>img {
                width: 55%;
            }
        }
    }

    &__title {
        margin: 0 auto;
        width: 80%;
        position: relative;
        padding: 0 rem(40px);
        border-left: 1px solid $borderColor;
        border-right: 1px solid $borderColor;

        padding-top: rem(21px);
        padding-bottom: rem(38px);

        @include media-min($sm) {
            width: 89%;
        }

        @include media-min($md) {
            padding: 0 2.5rem;
            padding-top: rem(31px);
            grid-row: 1;
            grid-column: 2 / 5;
            width: 100%;
            min-height: 100px;
        }

        h3 {
            margin: 0;
            font-weight: 300;
            font-size: rem(24px);

            @include media-min($md) {
                font-size: rem(24px);
            }
        }

        img {
            position: absolute;
            max-height: rem(60px);
            left: 0;
            // top: 30%;
            top: rem(15px);
            transform: translateX(-50%);

            @include media-min($md) {
                max-height: rem(60px);
            }
        }

        p {
            @include media-min($md) {
                font-size: rem(22px);
            }
        }

        &-noneBorderRight {
            border-right: none !important;
            padding-top: rem(60px);

            &>img {
                top: rem(60px);
            }
        }
    }


    &__cnt {
        grid-column: 1 / 6;
        grid-row: 2 / 2;

        @include media-max($sm) {
            display: grid;
            // margin-top: rem(60px);
        }

        @include media-min($md) {
            margin-top: rem(60px);
        }

        &-noMarginTop {
            margin-top: 0 !important;
        }

        &>div {
            justify-content: center;
        }

        &--item {
            // width: 100%;
            width: auto !important;
            height: rem(400px);

            position: relative;

            @include media-max($sm) {
                // width: 20%;
            }

            @include media-min($md) {
                // max-width: rem(386px);
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    .caseStudies__lightbox {
                        display: block;
                    }
                }
            }

            &>a {
                position: relative;
                height: 100%;
                width: auto;
            }
        }
    }

    &__img {
        cursor: pointer;
        transition: 0.3s;
        height: inherit;
        width: auto;
        object-fit: contain;
        position: relative;

        // visibility: hidden;

        @include media-min($sm) {
            min-width: auto;
        }

        &:hover {
            background-color: #000000;
            opacity: 0.2;

            +.caseStudies__overlay {
                visibility: visible !important;
                z-index: 1;
            }

            +.caseStudies__lightbox {
                z-index: 1;
            }


        }

        &:hover~.caseStudies__info,
        &:hover~.caseStudies__btn {
            z-index: 1;
            opacity: 1;
        }
    }

    &__info {
        z-index: -333;
        display: flex;
        justify-content: center;
        margin-bottom: rem(59px);
        font-size: rem(14px);
        font-weight: 400;
        pointer-events: none;
        opacity: 0;
        transition: opacity .5s;

        @include media-max($sm) {
            display: none;
        }
    }

    &__btn {
        z-index: -333;
        position: absolute;
        min-width: rem(65px);
        min-height: rem(65px);
        border-radius: 100%;
        background-color: #f16322;
        opacity: 0;
        transition: opacity .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        @include media-max($sm) {
            display: none;
        }

        &>i {
            font-size: rem(24px);
        }
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #0f0e0e;
        opacity: 0.8;
        visibility: visible;
    }
}