@mixin zindex {
  @for $i from 1 through 6 {
    .item_#{$i} {
      z-index: (7 - $i) !important;
    }
  }
}


.offer {
  background: #2a2c17;
  color: $white;

  @include media-min($md) {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr auto;
  }

  &__hidden {
    visibility: hidden;
    margin-top: rem(-122px);
    position: absolute;
  }

  &__title {
    margin: 0 auto;
    width: 80%;
    position: relative;

    padding-top: rem(24px);
    padding-bottom: rem(24px);
    padding-left: rem(42px);

    @include media-min($md) {
      grid-row: 1;
      grid-column: 2 / 5;
      margin: 0;
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;

      border-left: 1px solid $borderColor;
      border-right: 1px solid $borderColor;
    }

    i {
      display: none;

      @include media-min($sm) {
        display: block;
        transform: rotate(90deg);
        font-size: rem(20px);
        color: #60614f;
        margin-left: rem(10px);
        margin-right: rem(10px);
      }
    }

    h3 {
      margin: 0;
      font-weight: 700;
      font-size: rem(24px);
      text-transform: uppercase;

      @include media-min($md) {
        font-size: rem(24px);
      }
    }

    img {
      position: absolute;
      max-height: rem(60px);
      top: 10%;

      @include media-min($md) {
        max-height: rem(87px);
      }
    }

    img:first-of-type {
      left: 0;
      transform: translateX(-50%);

      @include media-min($sm) {
        left: rem(-15px);
      }

      @include media-min($md) {
        left: 0;
      }
    }

    img:nth-of-type(2) {
      display: none;

      @include media-min($md) {
        display: block;
        right: 0;
        transform: translateX(50%);
      }

    }

    p {
      font-size: rem(17px);
      font-weight: 400;
      margin: 0;
      // @include media-min($md) {
      //   font-size: rem(22px);
      // }
    }
  }

  &__aboutBtn {
    display: none;

    &--link {
      @include media-max($sm) {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        height: rem(80px);

        a {
          display: block;
          background: #505331;
          height: rem(70px);
          width: rem(250px);
          line-height: rem(67px);
          text-align: right;
          padding: rem(0 40px);
          font-size: rem(17px);
          font-weight: 700;
          position: relative;
          color: $white;
          transition: 0.8s;
          margin-left: rem(19px);
          z-index: 2;

          &::before {
            content: "";
            position: absolute;
            left: rem(40px);
            margin: auto 0;
            top: 0;
            bottom: 0;
            width: 25%;
            background-color: $white;
            height: 3px;
          }

          &:hover {
            background-color: $secondary;
            color: $white;
          }
        }

        span {
          position: absolute;
          border: 1px solid $borderColor;
          width: calc(100% - 16px);
          top: 0;
          right: 0;
          height: rem(180px);
          margin-left: rem(19px);
          z-index: 1;
        }
      }
    }

    &--small {
      a {
        height: rem(46px) !important;
        line-height: rem(43px) !important;
        padding: rem(0 23px) !important;
        font-size: rem(14px) !important;
        margin-left: rem(36px) !important;
        margin-right: rem(53px) !important;

        &::before {
          left: rem(19px) !important;
          width: 25% !important;
          height: 2px !important;
        }
      }
    }

    @include media-min($sm) {
      display: block;
      grid-row: 1;
      grid-column: 4 / 5;
      position: relative;

      a {
        display: block;
        background: #505331;
        height: rem(92px);
        line-height: rem(92px);
        text-align: right;
        padding: rem(0 40px);
        font-size: rem(17px);
        font-weight: 700;
        position: relative;
        color: $white;
        transition: 0.8s;
        margin-left: rem(19px);
        z-index: 2;

        &::before {
          content: "";
          position: absolute;
          left: rem(40px);
          margin: auto 0;
          top: 0;
          bottom: 0;
          width: 25%;
          background-color: $white;
          height: 3px;
        }

        &:hover {
          background-color: $secondary;
          color: $white;
        }
      }

      span {
        position: absolute;
        border: 1px solid $borderColor;
        width: calc(100% - 16px);
        top: 0;
        right: 0;
        height: rem(180px);
        margin-left: rem(19px);
        z-index: 1;
      }
    }

    @include media-min($xl) {
      span {
        width: calc(100% - 19px);
      }
    }

    @include media-min(2800px) {
      span {
        width: calc(100% - 40px);
      }
    }
  }

  &__cnt {
    width: 80%;
    margin: rem(0 auto 0 auto);

    @include media-min($sm) {
      width: 87%;
      margin-left: rem(58px);
    }

    @include media-min($md) {
      grid-row: 2;
      grid-column: 2 / 5;
      margin: 0;
      width: 100%;
      z-index: 10;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-min($sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-min($md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__base {
    &-overlay {
      width: 100%;
      height: 100%;
      background-color: #F16323;
      opacity: 0.6;
      position: absolute;
    }

    &-cnt {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-banair {
      display: block;
      grid-row: 1;
      grid-column: 4 / 5;
      position: relative;

      a {
        pointer-events: none;
        display: block;
        height: rem(92px);
        line-height: rem(92px);
        text-align: right;
        padding: rem(0 170px);
        font-size: rem(17px);
        font-weight: 700;
        position: relative;
        color: $white;
        transition: 0.8s;
        z-index: 2;

        &::before {
          content: "";
          position: absolute;
          left: rem(40px);
          margin: auto 0;
          top: 0;
          bottom: 0;
          width: 28%;
          background-color: $white;
          height: 3px;
        }
      }

      @include media-min-max($md, $lg) {
        a {
          padding: rem(0 20px);
        }
      }

      span {
        position: absolute;
        border: 1px solid $borderColor;
        width: calc(100% - 16px);
        top: 0;
        right: 0;
        height: rem(180px);
        margin-left: rem(19px);
        z-index: 1;
      }
    }

    &-text {
      margin-left: rem(40px);
      margin-bottom: rem(40px);
      max-width: 70%;
      word-break: break-word;
      font-size: 2.1875rem;
      letter-spacing: 0.00625rem;

      color: #fff;
      font-weight: 700;
      max-width: 18.75rem;
      font-size: 1.75rem;
      text-transform: uppercase;
      margin-top: 0rem;
      z-index: 1;
      @include media-min-max($md, $lg) {
        margin-left: rem(10px);
        margin-bottom: rem(20px);
      }
    }
  }

  &__item {
    position: relative;
    border-right: 1px solid $borderColor;
    border-left: 1px solid #60614fa3;

    &:nth-of-type(2n + 1) {
      background: #30321d;

      @include media-min($sm) {
        &:nth-of-type(2n + 1) {
          border-right: 1px solid #60614fa3;
        }
      }
    }

    @include media-min($md) {
      border-right: none;

      &:nth-of-type(3n) {
        border-right: 1px solid #60614fa3;
      }
    }

    &>a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.3s;

      &:hover {
        background-color: black;
        opacity: 0.2;
      }
    }

    img {
      width: 100%;
      height: rem(239px);
    }

    &-base {
      background-color: #F16323 !important;

      &>img {
        height: 100%;
        opacity: 0.2;
      }
    }

    &-title {
      height: rem(55px);
      display: flex;
      align-items: flex-end;
      margin-left: rem(30px);

      h4 {
        font-size: rem(18px);
        font-weight: 700;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      img {
        background-size: contain;
        max-width: rem(40px);
        max-height: rem(20px);
        margin-right: rem(15px);
      }

      span {
        min-width: rem(77px);
        height: auto;
      }

      span>svg {
        max-height: rem(23px);
        width: auto;
        font-size: rem(22px);
        margin-right: rem(54px);

        &>path {
          fill: inherit !important;
        }
      }

      @include media-min-max($md, $lg) {
        margin-left: rem(10px);

        & > span > svg {
          margin-right: rem(20px);
        }
      }
    }

    &-desc {
      width: 90%;
      color: white;
      font-size: rem(14px);
      font-weight: 400;
      margin: 0;
      padding-top: rem(14px);
      min-height: rem(125px);
      margin-left: rem(30px);

      @include media-min-max($md, $lg) {
        margin-left: rem(10px);
      }
    }
  }

  &__sublist {
    &-cnt {
      background: $secondary;
      position: absolute;
      top: 0 !important;
      left: 0 !important;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      z-index: 2;

      @include media-min($md) {
        background: transparent;
      }
    }

    &-title {
      position: absolute;
      z-index: 8;
      font-weight: 700;
      padding-left: rem(30px);
      display: flex;
      align-items: center;
      width: 100%;

      p {
        color: $white;
        padding: 0;
        margin: 0;
        margin: rem(40px 0);
        font-size: rem(19px);
      }

      img {
        background-size: contain;
        max-width: rem(40px);
        max-height: rem(20px);
        margin-right: rem(15px);
      }

      i {
        font-size: rem(22px);
        margin-right: rem(15px);

        &:before {
          color: white !important;
        }
      }
    }

    width: 100%;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: rem(20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include media-min($md) {
      height: 55%;
      position: absolute;
      bottom: 0;
      background: $secondary;
      box-shadow: 35px 1px 39px -31px rgba(0, 0, 0, 0.47);
      z-index: initial;
    }
  }

  &__hover {
    position: absolute;
    height: rem(45px);
    width: rem(45px);
    text-align: center;
    line-height: rem(45px);
    bottom: rem(5px);
    left: rem(15px);
    font-size: rem(30px);
    font-weight: 100;
    z-index: 2;

    @include media-min($md) {
      z-index: 0;
    }

    &:hover {
      color: $secondaryDark;
    }
  }

  &__subitem {
    font-size: rem(15px);
    font-weight: 700;
    width: 100%;
  }

  &__sublink {
    display: inline-block;
    width: 100%;
    color: $white;
    padding: rem(6px 26px);
    position: relative;

    &:hover {
      background: #d15219;
      color: $white;
      text-decoration: underline;

      &:after {
        position: absolute;
        content: "";
        width: rem(8px);
        height: rem(8px);
        border-top: 2px solid $white;
        border-right: 2px solid $white;
        margin: auto 0;
        right: 15px;
        top: 0;
        bottom: 0;
        transform: rotate(45deg);
      }
    }
  }

  &__droplist {
    &-cnt {
      position: absolute;
      z-index: 6;
      top: 0 !important;
      bottom: 0;
      width: 100%;
      height: 100%;
      margin-top: 0 !important;
      background-color: $secondary;

      @include media-min($md) {
        left: 100% !important;
        top: initial !important;
        bottom: 0 !important;
        height: 182%;
        z-index: -1;
      }
    }

    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__dropitem {
    font-size: rem(15px);
    font-weight: 600;
    width: 100%;
    text-transform: uppercase;

    @include media-min($md) {
      font-size: rem(18px);
    }
  }

  &__droplink {
    display: inline-block;
    width: 100%;
    color: $white;
    padding: rem(6px 26px);
    border-top: 1px solid rgba(225, 255, 255, 0.2);

    &:hover {
      background: $secondaryDark;
      color: $white;
      text-decoration: underline;
    }
  }

  &--box {
    display: none;

    @include media-min($md) {
      display: block;
      position: absolute;
      height: 11.25rem;
      width: 11.5625rem;
      border: 1px solid $borderColor;
      border-top: none;
      right: 1.25rem;
    }
  }

  &--boxRight,
  &--boxLeft {
    display: none;

    @include media-min($md) {
      display: block;
      position: absolute;
    }
  }

  &--boxRight {
    @include media-min($md) {
      bottom: 25%;
      right: 0;
      border: 1px solid $borderColor;
      height: 48%;
      width: 11.5625rem;
    }
  }

  &--boxLeft {
    @include media-min($md) {
      bottom: 0;
      left: 0;
      border-right: 1px solid $borderColor;
      height: 36%;
      width: 9rem;

      &::after {
        content: "";
        position: absolute;
        width: rem(1px);
        height: 40%;
        background: $borderColor;
        transform: translateY(-100%) rotate(-66deg);
        right: 0;
        transform-origin: bottom right;
      }
    }
  }
}

@include zindex;