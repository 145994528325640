.ebSlider {
    background-color: #1e1f11;
    color: $white;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__overlay {
        position: absolute;
        width: 100%;
        height: 76vh;
        background-color: #0f0e0e;
        opacity: 0.7;
        z-index: 1;
    }

    &__title {
        position: absolute;
        height: 76vh;
        width: 100%;
        display: flex;
        align-items: flex-end;
        padding-left: 10%;

        @include media-min($sm) {
            padding-left: 5.5%;
        }

        @include media-min($md) {
            padding-left: 20%;
            padding-right: 20%;
        }

        &-cnt {
            height: rem(94px);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80%;
            padding: 0;
            font-family: 'Banair';
            background-color: $primaryLight;
            z-index: 3;

            @include media-min($sm) {
                height: rem(160px);
                width: 100%;
                justify-content: left;
                padding-left: rem(64px);
            }

            @include media-min($md) {
            }

            @include media-min($lg) {
                width: 39.8vw;
            }

            &>h1 {
                text-transform: uppercase;
                font-weight: 400;
                font-size: rem(26px);

                &>span {
                    color: $secondaryLight;
                    font-weight: 700;
                }
            }
        }
    }

    &__slider {
        position: relative;
        display: flex;
        flex-direction: column;

        &-list {
            min-height: 76vh !important;
        }

        &-lines {
            display: none;

            @include media-min($md) {
                pointer-events: none;
                position: absolute;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: 100%;
                width: 1px;
                width: 100%;
                height: 100%;
                overflow: hidden;
                top: 0;
                left: 0;
                z-index: 4;

                span:nth-of-type(2) {
                    display: flex;
                    align-items: flex-end;
                }

                span:nth-of-type(2),
                span:nth-of-type(3) {
                    position: relative;

                }

                span:nth-of-type(4) {
                    border-right: 1px solid $borderColor;
                }
            }
        }

        &-bottomArea {
            position: relative;
            height: 24vh;
            background-color: #33351e;
            border-bottom: 1px solid #60614f;

            @include media-min($md) {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: 100%;
            }
        }

        &-desc {
            border: 1px solid $borderColor;
            height: inherit;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include media-max($sm){
                padding-top: rem(24px);
            }

            @include media-min($md) {
                grid-row: 1;
                grid-column: 2 / 5;
            }

            @include media-min($lg) {
                grid-column: 2 / 4;
            }

            &>p {
                padding-left: rem(51px);
                font-weight: 400;
            }
        }

        &-admission {
            font-weight: 400;
            color: #ffffff;
            padding-left: rem(51px);
            padding-right: rem(50px);
            font-size: rem(14px);
            letter-spacing: rem(-0.07px);
            opacity: 0.6;

            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            position: relative;

            @include media-min($sm){
                &:after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    margin: auto 0;
                    right: 50%;
                    background-color: #4B4C41;
                    bottom: 0;
                    height: 15%;
                }
    
                &:before {
                    content: "";
                    position: absolute;
                    width: 1px;
                    margin: auto 0;
                    left: 50%;
                    background-color: #4B4C41;
                    top: 0;
                    height: 15%;
                }
            }
        }

        &-content {
            font-weight: 400;
            color: #000000;
            padding-left: rem(51px);
            padding-right: rem(100px);
        }

        &-title {
            left: 10%;
            height: 94px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80%;
            padding: 0;

            position: absolute;
            font-family: 'Banair';
            background-color: $primaryLight;
            z-index: 3;
            top: rem(-121px);

            @include media-min($sm) {
                height: rem(160px);
                padding-left: rem(64px);
                padding-bottom: rem(50px);
                display: flex;
                align-items: flex-end;
            }

            @include media-min($lg) {
                width: 39.8vw;
            }

            &>h1 {
                text-transform: uppercase;
                font-weight: 400;
                font-size: rem(26px);

                &>span {
                    color: $secondaryLight;
                    font-weight: 700;
                }
            }
        }

        &-item {}

        &-img {
            object-fit: cover;
            object-position: center;
            min-width: 100%;
            min-height: 100%;

            @include media-min($sm) {
                transform: translate(-50%, -50%) !important;
            }
        }

        &-cnt {
            z-index: 3;
            color: $white;
            font-size: rem(16px);
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            min-height: 100%;

            @include media-min($sm) {
                align-items: flex-start;
                padding-left: 20%;
                margin-left: rem(50px);
                margin-top: rem(50px)
            }
        }

        &-heading {
            color: $white;
            font-weight: 700;
            max-width: rem(300px);
            font-size: rem(30px);

            @include media-min($md) {
                font-size: rem(60px);
                max-width: 60%;
                word-break: break-word;
            }
        }

        &-subtext {
            color: $white;
            font-weight: 300;
            max-width: rem(300px);
            font-size: rem(12px);

            @include media-min($md) {
                max-width: 40%;
                word-break: break-word;
            }
        }

        &-nav {
            position: absolute;
            z-index: 5;
            top: 0;
            left: rem(12px);
            min-height: 100%;
            list-style: none;
            padding: 0;
            margin: 0;
            padding-top: rem(300px);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            border-right: rem(1px) solid $borderColor;

            @include media-min($sm) {
                left: rem(10px);
            }

            @include media-min-max($md, $lg){
                padding-top: rem(220px);
            }

            @include media-min($md) {
                left: rem(28px);
                min-height: 97%;
            }

            @include media-min($md) {
                left: 20%;
                transform: translateX(-98%);
            }

            .uk-active a {
                opacity: 1;
                font-weight: 600;

                img {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &-navItem {
            font-size: rem(10px);
            width: rem(30px);
            counter-increment: step;

            @include media-min($md) {
                font-size: rem(12px);
                width: rem(60px);
            }

            a {
                color: $white;
                text-align: left;
                position: relative;
                width: 100% !important;
                height: rem(60px);
                display: block;
                opacity: 0.6;

                @include media-min($md) {
                    height: rem(120px);
                }

                &:after {
                    @include media-min($md) {
                        content: "0"counter(step);
                        position: absolute;
                        left: 68vw;
                    }

                    @include media-min($lg) {
                        left: 65vw;
                    }
                }


                @include media-min($xl) {
                    height: rem(100px);
                }

                &:hover {
                    opacity: 1;
                }

                img {
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    height: rem(80px);
                    left: 100%;
                    transform: translate(-47%, -50%);
                    transition: visibility 0.5s linear, opacity 0.5s linear;

                    @include media-min($md) {
                        height: rem(126px);
                    }

                    @include media-min($xl) {
                        height: rem(100px);
                    }
                }
            }

        }
    }

    &__moreCnt {
        grid-column: 4 / 5;
        position: relative;
        grid-row: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding-bottom: rem(50px);
        padding-right: rem(70px);

        @include media-max($lg) {
            display: none;
        }
    }

    &__more {
        display: none;

        @include media-min($lg) {
            display: block;
        }

        &>span {
            &>a>img {
                animation: mover 1s infinite alternate;
            }
        }
    }
}