.aboutBanner {
    background-color: #33351e;
    height: 89vh;
    color: $white;
    align-items: center;
    justify-content: center;

    @include media-max($sm) {
        margin-top: rem(70px);
        position: relative;
        // display: flex;
        // align-items: baseline;
    }

    @include media-min($sm) {
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        margin-top: rem(80px);
        height: 93vh;
    }

    @include media-min($lg) {
        height: 89vh;
        margin-top: rem(120px);
    }

    &__slider {
        position: relative;
        display: flex;
        flex-direction: column;

        &-list {
            min-height: 89vh !important;
        }

        &-lines {
            display: none;

            @include media-min($sm) {
                pointer-events: none;
                position: absolute;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: 100%;
                width: 1px;
                width: 100%;
                height: 100%;
                overflow: hidden;
                top: 0;
                left: 0;
                z-index: 4;

                span:nth-of-type(2) {
                    display: flex;
                    align-items: flex-end;
                }

                span:nth-of-type(2),
                span:nth-of-type(3) {
                    position: relative;

                }

                span:nth-of-type(4) {
                    border-right: 1px solid $borderColor;
                }
            }
        }

        &-img {
            object-fit: cover;
            object-position: center;
            min-width: 100%;
            min-height: 100%;

            @include media-min($sm) {
                transform: translate(-50%, -50%) !important;
            }
        }

        &-nav {
            position: absolute;
            z-index: 5;
            top: 0;
            left: rem(12px);
            min-height: 100%;
            list-style: none;
            padding: 0;
            margin: 0;
            padding-top: rem(300px);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            border-right: rem(1px) solid $borderColor;

            @include media-min($sm) {
                left: rem(10px);
            }

            @include media-min-max($md, $lg){
                padding-top: rem(220px);
            }

            @include media-min($md) {
                left: rem(28px);
                min-height: 97%;
            }

            @include media-min($md) {
                left: 20%;
                transform: translateX(-98%);
            }

            @include media-min($sm){
                .uk-active a {
                    opacity: 1;
                    font-weight: 600;
    
                    img, span {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

        }

        &-navItem {
            font-size: rem(10px);
            width: rem(30px);
            counter-increment: step;

            @include media-min($md) {
                font-size: rem(12px);
                width: rem(60px);
            }

            a {
                color: $white;
                text-align: left;
                position: relative;
                width: 100% !important;
                height: rem(60px);
                display: block;
                opacity: 0.6;

                @include media-min($md) {
                    height: rem(120px);
                }

                &:after {
                    @include media-min($sm) {
                        content: "0"counter(step);
                        position: absolute;
                        left: 85vw;
                    }

                    @include media-min($md){
                        left: 68vw;
                    }

                    @include media-min($lg) {
                        left: 65vw;
                    }
                }


                @include media-min($xl) {
                    height: rem(100px);
                }

                &:hover {
                    opacity: 1;
                }

                & > span {
                    left: 60vw;
                    position: absolute;
                    visibility: hidden;
                    transform: translate(-47%, -50%);
                    transition: visibility 0.5s linear, opacity 0.5s linear;
                    font-weight: 400;

                    @include media-min($sm){
                        height: rem(40px);
                        transform: translate(-47%, 0%);
                        left: 70vw;
                        width: rem(150px);
                    }
                    @include media-min($md){
                        height: rem(40px);
                        transform: translate(-47%, 0%);
                        left: 55vw;
                        display: flex;
                        justify-content: flex-end;
                        width: rem(150px);
                    }

                    @include media-min($lg){
                        left: 57vw;
                    }
                }

                & > img {
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    height: rem(80px);
                    left: 62.8vw;
                    transform: translate(-47%, -50%);
                    transition: visibility 0.5s linear, opacity 0.5s linear;

                    @include media-min($sm){
                        height: rem(40px);
                        left: 78.5vw;
                    }

                    @include media-min($md) {
                        height: rem(60px);
                        left: 65vw;
                    }

                    @include media-min($lg) {
                        height: rem(80px);
                        left: 62.8vw;
                    }

                    @include media-min($xl) {
                        height: rem(60px);
                    }
                }
            }

        }
    }

    &__box {
        grid-column: 2 / 5;
        position: absolute;
        max-width: rem(900px);
        display: flex;
        font-size: rem(37px);
        font-weight: 700;
        flex-direction: column;
        justify-content: center;
        z-index: 5;
        height: 100%;
        width: 84%;

        margin-left: 10%;


        @include media-max($xsm) {
            margin-top: rem(20px);
            margin-left: rem(0px);

            width: 78%;
            margin-top: rem(95px);
            max-height: 73%;
        }

        @include media-min($sm) {
            width: 84%;
        }

        @include media-min-max($sm, $md) {
            grid-column: 1 / 5;
            margin-left: rem(71px);
        }

        @include media-min($lg) {
            grid-column: 2 / 5;
            margin-left: 0;
        }
    }

    &__title {
        & > img {
            max-height: rem(126px);
            min-width: rem(21px);
            position: absolute;
            left: 10%;

            @include media-min($sm){
                left: -8.5%;
            }

            @include media-min($md){
                left: -13.5%;
            }
            @include media-min($lg){
                left: rem(-11px);
            }
        }

        padding-left: rem(45px);
        font-weight: 700;
        line-height: rem(42px);

        @include media-max($sm) {
            font-size: rem(20px);
            line-height: rem(36px);
        }

        @include media-min($sm) {
            font-size: rem(30px);
        }

        &-cnt {
            width: 39%;
            margin-left: 10%;

            @include media-min($sm){
                margin-left: 0;
            }

            &-lighter {
                font-weight: 300;
                margin-bottom: 0;
            }

            &-bolder {
                font-weight: 800;
                font-size: rem(36px);
            }
        }
    }

    &__watch {
        cursor: pointer;
        width: rem(339px);
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-top: rem(64px);
        padding-bottom: rem(53px);
        border-bottom: rem(2px) solid white;

        @include media-max($sm) {
            padding-bottom: rem(40px)
        }

        @include media-min($md) {
            margin-left: rem(43px);
        }

        img {
            max-width: rem(57px);
            min-height: rem(57px);
        }

        p {
            padding-left: rem(25px);
            font-size: rem(16px);
        }
    }

    &__lines {
        position: absolute;
        top: rem(60px);
        left: 0;
        width: 100%;
        height: rem(2px);
        border-top: rem(1px) solid #60614f;

        @include media-max($lg) {
            display: none;
        }

        @include media-min($sm) {
            display: none;
        }
    }

    &__overlay {
        display: grid;
        grid-column: 3 / 6;

        width: inherit;
        height: inherit;
    }

    &__overlayDark {
        position: absolute;
        width: 100%;
        height: 89vh;
        background-color: #0f0e0e;
        opacity: 0.7;
        z-index: 1;
    }

    &__picture {
        & > img {
            display: block;
            object-fit: cover;
            object-position: 85% 85%;
            min-width: 100%;
            min-height: 100%;
            height: rem(608px);

            @include media-min($xl) {
                height: rem(608px)
            }

            @include media-min($xxl) {
                height: rem(628px);
            }
        }
    }
}