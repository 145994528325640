// .subpageInfo{
//     display: grid;
//     grid-template-columns: repeat(5, 1fr);
//     background-color: #33351e;
//     height: 275px;
//     width: 100%;
//     border-bottom: 1px solid $borderColor;

//     &__cnt{
//         display: grid;

//         grid-template-columns: 1fr 1fr;
//         grid-template-rows: 1fr 1fr;
//         grid-template-areas: 
//             "text text"
//             "text text";

//         grid-column: 2 / 4;
//         width: initial;
//         margin: 0;
//         color: white;
//     }

//     &__arrow{
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         margin-right: rem(100px);

//         img{
//             animation: mover 1s infinite alternate;
//         }
//     }

//     &__text{
//         padding: 9rem 7rem 1rem 2rem;;
//         grid-area: text;
//         text-align: justify;

//         ul {
//             list-style: none;
//             padding: 0;
//             line-height: 24px;
//         }

//         h3{
//             font-weight: 600;
//         }
//     }
// }