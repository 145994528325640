.video {
    background: #2a2c17;
    color: $white;

    @include media-min($md) {
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr auto;
    }

    &__modal {
        width: 70%;
        height: 70%;

        & > iframe {
            width: 100%;
            height: 100%;
        }
    }

    &__title {
        margin: 0 auto;
        min-height: rem(150px);
        width: 80%;
        position: relative;
        padding: 0 rem(40px);
        padding-top: rem(50px);
        border-left: 1px solid $borderColor;
        border-right: 1px solid $borderColor;

        @include media-min($sm) {
            width: 89%;
        }

        @include media-min($md) {
            grid-row: 1;
            grid-column: 2 / 5;
            margin: 0;
            width: 100%;
        }

        h3 {
            margin: 0;
            font-weight: 400;
            font-size: rem(24px);

            @include media-min($md) {
                font-size: rem(24px);
            }
        }

        img {
            position: absolute;
            max-height: rem(60px);
            left: 0;
            top: 30%;
            transform: translateX(-50%);

            @include media-min($md) {
                left: 0;
                max-height: rem(87px);
            }
        }

        p {
            @include media-min($md) {
                font-size: rem(22px);
            }
        }
    }

    &__cnt {
        border-left: 1px solid $borderColor;
        border-right: 1px solid $borderColor;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        // margin: 0 2.5rem;

        width: 80%;
        margin: 0 auto;

        @include media-min($sm) {
            width: 89%;
        }

        @include media-min($md) {
            width: 100%;
            margin: 0;
            grid-column: 2 / 5;
        }
    }

    &__elem {
        height: auto;
        margin-bottom: rem(40px);
        position: relative;
        width: rem(500px);
        height: rem(258px);
    }

    &__overlay {
        background-color: #0f0e0e;
        opacity: 0.54;

        margin: 0 auto;
        width: auto;
        max-height: rem(267px);
        background-color: #0f0e0e;
        opacity: 0.54;

        width: 100%;
        height: 100%;
    }

    &__picture {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }

    &__play {
        top: 56%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: rem(12px);

        position: absolute;

        h4 {
            margin-top: rem(19px);
            margin-bottom: 0;
            font-weight: 700;
        }

        p {
            margin-top: rem(11px) !important;
            font-weight: 400;
        }

        img {
            width: auto;
            max-height: rem(58px);

            @include media-min($lg){
                max-height: rem(78px);
            }
        }
    }
}