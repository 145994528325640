.capabilities {
    background-color: #33351E;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 10% 1fr;
    grid-template-rows: rem(180px) 1fr 100%;

    @include media-min($sm){
        grid-template-columns: 5.5% 1fr;
    }
    @include media-min($md){
        height: 100%;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: rem(180px) 70% 10%;
    }
    @include media-min($lg){
        height: 100vh;
        grid-template-rows: rem(180px) 1fr 1fr;
    }

    &__title {
        grid-column: 2 / 5;
        grid-row: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        color: white;
        border-left: 1px solid #60614f;

        &>img {
            height: rem(75px);
            position: absolute;
            left: rem(-10px);
        }

        &>h3 {
            margin-left: rem(40px);
            font-size: rem(34px);
            letter-spacing: rem(-0.17px);

            @include media-min($sm){
                margin-left: rem(64px);
            }
        }
    }

    &__cnt {
        grid-column: 2 / 5;
        grid-row: 2;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: rem(40px);

        @include media-min($sm){
            padding-left: rem(60px);         
            grid-row: 2;   
        }
    }

    &__item {
        width: rem(290px);
        margin-right: rem(60px);
        margin-bottom: rem(90px);

        display: grid;
        grid-template-rows: 40% 20% 40%;

        @include media-min($sm){
            width: rem(250px);
        }

        @include media-min($lg){
            width: rem(290px);
        }

        @include media-max($sm){
            & > div:not(.capabilities__item-desc){
                text-align: center;
            }
        }

        &-icon {
            height: 100%;
            width: auto;

            margin-bottom: rem(30px);
        }

        &-title {
            font-weight: 600;
            font-size: rem(20px);
            color: white;
        }

        &-desc {
            color: #D8D8D87A;
            letter-spacing: 0.21px;
            font-size: rem(14px);
        }
    }

    &__more {
        display: block;
        grid-row: 3;
        grid-column: 2 / 5;
        width: rem(382px);
        height: max-content;

        @include media-min($sm){
            margin-left: rem(40px);
        }

        a {
            display: block;
            background: #505331;
            height: rem(92px);
            line-height: rem(92px);
            text-align: right;
            padding: rem(0 40px);
            font-size: rem(17px);
            font-weight: 700;
            position: relative;
            color: $white;
            transition: 0.8s;
            margin-left: rem(19px);
            z-index: 2;

            &::before {
                content: "";
                position: absolute;
                left: rem(40px);
                margin: auto 0;
                top: 0;
                bottom: 0;
                width: 25%;
                background-color: $white;
                height: 3px;
            }

            &:hover {
                background-color: $secondary;
                color: $white;
            }
        }

        span {
            position: relative;
            width: calc(100% - 16px);
            top: 0;
            right: 0;
            margin-left: rem(19px);
            z-index: 1;
        }

        @include media-min($xl) {
            span {
                width: calc(100% - 19px);
            }
        }

        @include media-min(2800px) {
            span {
                width: calc(100% - 40px);
            }
        }
    }
}