.specification {
    background: #fffffa;
    color: #33351e;
    margin: -2px 0;

    @include media-max($sm) {
        height: 100%;
    }

    @include media-min($md) {
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: rem(80px) 100%;
    }

    @include media-min($lg) {
        grid-template-rows: rem(80px) 100%;
        min-height: rem(200px);
        z-index: 1;
    }

    &__paddingTop {
        grid-template-rows: rem(150px) 1fr;

        .specification__title {
            display: flex;
            align-items: flex-end;
            padding-bottom: rem(19px);
        }

        .specification__title img {
            top: rem(91px);
        }
    }

    &__title {
        margin: 0 auto;
        width: 80%;
        position: relative;
        padding: 0 rem(46px);
        padding-top: rem(20px);
        border-left: 1px solid $borderColorLight;

        @include media-max($lg) {
            padding-bottom: rem(16px);
        }

        @include media-min($md) {
            grid-row: 1;
            grid-column: 2 / 5;
            margin: 0;
            width: 100%;
        }

        h2 {
            margin: 0;
            font-weight: 400;
            font-size: rem(24px);

            @include media-min($md) {
                font-size: rem(24px);
            }
        }

        img {
            transform: translateX(-50%);
            left: 0;
            position: absolute;
            top: rem(10px);
            grid-column: 2 / 2;
            z-index: 1;
            max-height: rem(60px);

            @include media-min($md) {
                max-height: rem(60px);
            }

            @include media-min($xl) {
                max-height: rem(70px);
            }

        }
    }

    &__cnt {
        width: 80%;
        margin: 0 auto;
        border-left: 1px solid $borderColorLight;

        @include media-min($md) {
            width: 100%;
            grid-row: 2;
            grid-column: 2 / 6;
        }

        @include media-min($lg) {
            grid-column: 2 / 5;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &-bottomPadding {
            padding-bottom: rem(100px);
        }

        .uk-slideshow {
            @include media-min($lg) {
                height: 100%;
                width: 100%;
            }
        }

        &-oneDesc {
            width: 100%;
            position: relative;
        }
    }

    &__items {
        @include media-max($sm) {
            min-height: rem(500px) !important;
        }

        @include media-min($lg) {
            min-height: unset !important;
            overflow: inherit;
        }

        &>li {
            // position: relative !important;
            @include media-min($sm) {
                overflow: inherit;
            }
        }
    }

    &__slider {
        display: grid;
        min-height: 100%;
        width: 100%;
        color: #2a2c17;

        @include media-max($sm) {
            display: block;
        }

        @include media-min($lg) {
            grid-template-columns: 60% 40%;
        }

        div:first-child {
            grid-column: 1;
        }

        img {
            position: relative;
        }
    }

    &__description {
        padding-left: rem(46px);
        padding-right: rem(51px);
        z-index: 1;

        @include media-min($lg) {
            width: 100%;
            position: relative;
            left: 0;
        }

        h3 {
            font-size: rem(22px);
            font-weight: 700;
        }

        p:first-child {
            margin-bottom: 0;
            max-width: rem(600px);
            font-size: rem(22px);
            font-weight: 700;
            line-height: rem(40.77px);
        }

        ul {
            @include list-clear;
            margin-top: rem(20px);
            font-size: rem(14px);
            font-weight: 500;
            line-height: rem(38px);
            margin-left: rem(38px);

            @include media-max($sm) {
                margin-top: rem(40px);
                margin-left: rem(23px);
                margin-right: rem(8px);
                line-height: rem(31px);
            }
        }

        ul>li:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 0.5625rem;
            height: 0.5625rem;
            background-color: #f16322;
            margin-top: rem(13px);
            margin-left: rem(0px);

            @include media-max($md) {
                left: rem(40px);
            }

            @include media-min($md) {
                margin-left: rem(60px);
            }
        }
    }

    &__nav {
        display: flex;
        flex-direction: row;
        width: fit-content;
        position: absolute;
        bottom: rem(-129px);
        right: 0;
        padding-bottom: rem(10px);
        height: rem(20px);
        align-items: center;

        @include media-max($lg) {
            position: relative;
            width: 100%;
            justify-content: center;
            padding: 0 0 rem(22px) 0;
            bottom: rem(0px);
        }

        @include media-min($md) {
            bottom: rem(24px);
        }

        @include media-min($lg) {
            bottom: 0;
            right: 30%;
        }

        ul {
            display: flex;
            align-content: center;
            width: fit-content;
            margin: 0 rem(10px) 0 rem(10px);
            min-height: rem(20px) !important;
        }

        ul li {
            height: rem(7px);
        }

        ul li:not(:first-child) {
            padding-left: rem(6px) !important;
        }

        ul li:first-child {
            padding-left: 0 !important;
        }

        ul li a {
            background-color: #0f0e0e !important;
            border-radius: 100%;
            height: rem(7px);
            width: rem(7px);
            display: block;
        }

        ul li:not(.uk-active) a {
            opacity: 0.33;
        }

        &>a:first-of-type {
            transform: rotate(180deg);
        }

        &>a:nth-of-type(2) {
            & > img {
                margin-bottom: rem(1px);
            }
        }
    }

    &__sliderImg {
        position: relative;
        @include media-max($lg) {
            margin-top: rem(20px);
        }

        @include media-min($md) {
            display: block;
            position: relative;
        }

        @include media-min($lg) {
            grid-column: 2;
            right: 0;
            display: flex;
            max-height: rem(400px);
            min-height: rem(400px);
            justify-content: flex-end;
            align-items: center;

            width: 100%;
        }

        &>img {
            max-height: inherit;
        }

        &>a {
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            min-width: rem(65px);
            min-height: rem(65px);
            border-radius: 100%;
            background-color: #f1642280;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-max($lg) {
                max-width: rem(65px);
                max-height: rem(65px);
                border-radius: 100%;
            }

            &>i {
                font-size: rem(36px);
                color: #ffffff;
            }
        }
    }
}