.subpageDetails {
    background: #2a2c17;
    color: $white;

    @include media-min($md) {
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr auto;
    }

    &__title {
        // margin: 0 auto;
        position: relative;
        // width: 80%;
        width: 100%;
        padding: 0 rem(40px);
        padding-top: rem(50px);

        @include media-min($md) {
            grid-row: 1;
            grid-column: 2 / 5;
            margin: 0;
            width: 100%;
            padding-left: 0;
        }

        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 1px;
            height: rem(270px);
            background: $borderColor;

            @include media-max($sm) {
                // left: rem(-15px);
                left: 2.8125rem;
            }

            @include media-min($sm){
                left: 4.375rem;
                // left: rem(-3px);
            }

            @include media-min($md) {
                left: 0;
            }
        }

        h3 {
            margin: 0;
            font-weight: 300;
            font-size: rem(34px);

            padding-left: rem(40px);

            @include media-min($sm) {
                padding-left: rem(80px);
            }

            @include media-min($md) {
                font-size: rem(32px);
                padding-left: rem(50px);
            }
        }

        img {
            transform: translateX(-15%);
            // left: 0;
            position: absolute;
            top: rem(50px);
            grid-column: 2 / 2;
            z-index: 1;
            max-height: rem(70px);

            @include media-min($sm) {
                transform: translateX(126%);
            }

            @include media-min($md) {
                height: rem(90px);
                transform: translateX(-45%);
            }

            @include media-min($xl) {
                height: rem(70px);
            }
            
        }

        p {
            @include media-min($md) {
                font-size: rem(22px);
            }
        }
    }

    &__cnt {
        width: 80%;
        margin: rem(80px auto 0 auto);
        padding-bottom: rem(0px);
        padding-left: rem(40px);

        @include media-min($md) {
            grid-row: 2;
            grid-column: 2 / 5;
            margin: 0;
            width: 100%;
            margin: rem(70px 0);
            z-index: 10;
        }

        &::before {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 1px;
            height: rem(180px);
            background: $borderColor;

            @include media-min($sm) {
                left: rem(-15px);
            }

            @include media-min($md){
                left: rem(230px);
            }

            @include media-min($lg) {
                left: rem(380px);
            }
        }

        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            right: 0;
            width: 1px;
            height: rem(180px);
            background: $borderColor;

            @include media-min($sm) {
                // right: rem(-15px);
                width: 0;
            }

            @include media-min($md){
                width: rem(1px);
                right: rem(230px);
            }

            @include media-min($lg) {
                right: rem(380px);
            }
        }

        form {
            @include media-max($sm){
                display: flex;
                flex-direction: column;
            }

            @include media-min($sm){
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: 6fr auto;
                grid-template-areas:
                    "name surname"
                    "company company"
                    "phone email"
                    "description description"
                    "reg reg"
                    "submit ...";

                

                #name {
                    margin-right: rem(30px);
                    grid-area: name;
                }

                #surname {
                    grid-area: surname;
                }

                #company {
                    grid-area: company;
                }

                #phone {
                    margin-right: rem(30px);
                    grid-area: phone;
                }

                #email {
                    grid-area: email;
                }

                #description {
                    grid-area: description;
                }

                label,
                #reg-container {
                    grid-area: reg;
                }

                input[type=submit] {
                    grid-area: submit;
                }
            }

            input, textarea {
                font-size: rem(16px);
            }

            label {
                line-height: rem(0px);
            }

            input,
            textarea,
            label {
                margin-bottom: rem(32px);
            }

            input[type=submit] {
                -webkit-appearance: none;
                margin-top: rem(20px);
                border: 0;
        background-color: #f16322;
                max-width: rem(400px);
                min-height: rem(60px);
                text-align: left;
                padding-left: rem(25px);
                font-size: rem(18px);
                font-weight: 700;
                line-height: rem(38px);
            }

            input[type=text],
            input[type=email],
            input[type=tel],
            textarea {
                color: white;
                background-color: transparent;
                border: 0;
                border-bottom: 1px solid #f16322;
                height: rem(44px);
                padding-left: rem(14px);
            }

            input:focus,
            textarea:focus {
                outline: none;
            }

            textarea {
                height: rem(100px);
                resize: none;
            }

            input[type=checkbox] {
                position: absolute; // take it out of document flow
                opacity: 0; // hide it
            }

            #reg-container p{
                margin-left: rem(60px);
            }

            #reg-container label{
                position: relative;
            }

            #reg-container label:before,
            #reg-container label:after{
                position: absolute;
            }

            #reg-container label:before {
                // position: relative;
                cursor: pointer;
                padding: 0;
                content: '';
                margin-right: rem(10px);
                display: inline-block;
                // vertical-align: text-top;
                width: rem(15px);
                height: rem(15px);
                background: transparent;
                border: 1px solid white;
                top: rem(5px);
                left: rem(20px);
            }

            #reg-container label:after {
                cursor: pointer;
                color: #f16322;
                content: "";
                display: inline-block;
                height: rem(6px);
                width: rem(9px);
                border-left: 2px solid;
                border-bottom: 2px solid;
                transform: rotate(-45deg);
                top: rem(6px);
                left: rem(22px);
            }

            #reg-container input[type="checkbox"] + label::after {
                content: none;
            }

            #reg-container input[type="checkbox"]:checked + label::after {
                content: "";
            }
        }
    }
}