.more {
    width: 100%;
    position: relative;
    max-height: 100%;
    color: white;
    height: 100vh;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media-min($md) {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }

    @include media-min($lg) {
        height: 83vh;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include media-min($md) {
            flex-direction: row;
        }

        &-left {
            height: 50%;
            width: 100%;

            &>img {
                height: 100%;
                width: 100%;
            }

            @include media-min($md) {
                height: 100%;
                width: 50%;
            }

            @include media-min($lg) {
                height: 83vh;
            }

        }

        &-right {
            height: 50%;
            width: 100%;

            &>img {
                height: 100%;
                width: 100%;
            }

            @include media-min($md) {
                height: 100%;
                width: 50%;
            }

            @include media-min($lg) {
                height: 83vh;
            }
        }
    }

    &__cnt {
        z-index: 11;
        align-self: end;
        height: 100%;

        @include media-min($sm) {
            position: relative;
        }

        @include media-min($md) {
            grid-row: 1 / 2;
            grid-column: 2 / 5;
            margin: 0;
            width: 100%;
            height: 100%;
        }

        &>img {
            transform: translateX(-50%);
            left: 0;
            position: absolute;
            grid-column: 2 / 2;
            z-index: 1;
            left: rem(45px);
            bottom: rem(200px);

            @include media-min($sm) {
                left: rem(70px);
            }

            @include media-min($md) {
                height: rem(120px);
                bottom: rem(150px);
                left: 0;
            }

            @include media-min($lg) {
                height: rem(120px);
            }
        }

        &-desc {
            width: 100%;
            height: 100%;
            display: grid;
            position: relative;
            grid-template-rows: repeat(2, 1fr);

            @include media-min($md) {
                grid-template-columns: repeat(2, 1fr);
            }

            &>p:first-of-type {
                grid-row: 1;
                display: flex;
                width: 70%;
                align-items: center;
                margin-left: 17%;

                @include media-min($md) {
                    width: 80%;
                    align-items: flex-end;
                    grid-column: 1;
                    grid-row: 2;
                    margin-left: rem(50px);
                    margin-bottom: rem(120px);
                }
            }

            &>p:last-of-type {
                grid-row: 2;
                display: flex;
                width: 70%;
                align-items: center;
                margin-left: 17%;

                @include media-min($md) {
                    align-items: flex-start;
                    width: 100%;
                    grid-column: 2;
                    grid-row: 1;
                    margin-left: rem(70px);
                    margin-top: rem(50px);
                }
            }

        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #0f0e0e;
        opacity: 0.54 !important;
        width: 100%;
        height: 100%;
    }

    &__line {
        z-index: 1;
        position: absolute;

        border-left: 1px solid $borderColor;
        height: 100%;
        pointer-events: none;

        width: 78%;
        left: 2.8125rem;

        @include media-min($sm) {
            left: 4.375rem;
        }

        @include media-min($md) {
            left: 20%;
            transform: translateX(-100%);
        }
    }
}