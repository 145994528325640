.map {
    background-color: #505331;
    color: $white;
    z-index: 0;

    @include media-max($sm) {
        display: block;
        margin-top: rem(150px);
        height: 100%;
    }

    @include media-min($sm){
        position: relative;
        height: 92vh;
        margin-top: rem(150px);
    }

    @include media-min($md) {
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        height: 77vh;
        margin-top: rem(75px);
    }

    @include media-min($lg) {
        // height: 93vh;
        height: 100%;
        // margin-top: rem(120px);
        margin-top: rem(180px);
    }

    &>span:first-of-type {
        grid-column: 3 /4;
        border-right: 1px solid $borderColor;
        border-left: 1px solid $borderColor;
    }

    &>span:nth-of-type(2) {
        grid-column: 4 / 4;
        grid-row: 1 / 3;
        border-right: 1px solid $borderColor;
        border-left: 1px solid $borderColor;
    }

    &__details {
        grid-column: 2 / 2;
        grid-row: 1 / 1;
        padding-left: rem(20px);
        border-left: 1px solid $borderColor;
        z-index: 3;

        p {
            font-size: rem(13px);
            font-weight: 300;
            width: rem(320px);

            @include media-min($md) {
                width: 100%;
            }
        }
    }

    &__box {
        z-index: 1;
        background-color: #2f3420;
        grid-row: 1 / 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: end;
        border-left: 1px solid $borderColor;
        height: 100%;
        min-height: rem(750px);

        @include media-max($sm) {
            padding: rem(20px) rem(60px) rem(10px) rem(20px);
        }

        @include media-min($sm) {
            grid-column: 1 / 4;
            padding: rem(80px) rem(90px) rem(29px) rem(58px);
        }

        @include media-min($md){
            grid-column: 1 / 5;
            padding: rem(80px) rem(90px) rem(29px) rem(58px);
        }

        @include media-min($lg) {
            justify-content: flex-end;
            grid-column: 1 / 3;
            margin-right: rem(30px);
            z-index: 3;
            opacity: 0.9;
            padding-bottom: rem(140px);
        }

        @include media-min($lg) {
            margin-right: rem(200px);
        }

        h1 {
            color: #bc5022;
            text-transform: uppercase;
            font-size: rem(38px);
            font-weight: 600;
        }

        h2 {
            color: #4f5330;
            font-size: rem(26px);
            font-weight: 600;
        }

        p {
            font-size: rem(21px);
            font-weight: 400;
            color: #ffffff;
            text-align: justify;
        }

        span {
            font-size: rem(18px);
            font-weight: 400;
            color: #ffffff;
            text-transform: uppercase;
            text-align: right;
        }

        &-slider {
            grid-row: 1 / 3;
            height: 100%;

            @include media-min($md){
                grid-column: 1 / 5;
                margin-right: rem(30px);
                z-index: 3;
                opacity: 0.9;
            }

            @include media-min($lg) {
                grid-column: 1 / 3;
                margin-right: rem(200px);
                z-index: 3;
                opacity: 0.9;
            }

            // & > ul:first-of-type { 
            & > div { 
                display: none;
                background-color: #c65827;
                visibility: hidden;
                opacity: 0;
                transition: visibility 0.5s linear, opacity 0.5s linear;

                @include media-min($sm){
                    grid-column: 1 / 4;
                }

                @include media-min($md){
                    display: block;
                }
            }

            // &>ul {
            &>div>ul, &>div {
                height: 100%;
            }

            & >div > ul > li {
                height: 100%;
                position: relative !important;
            }
        }
    }

    &__slider {
        display: flex;
        flex-direction: column;
        padding-top: rem(35px);
        height: 100%;
        position: relative;
        padding-bottom: rem(20px);

        &>span,
        &>h3,
        &>p {
            padding-left: rem(15px);
            padding-right: rem(50px);

            @include media-min($sm){
                width: 100vw;
                padding-left: rem(40px);
                padding-right: rem(100px);
            }

            @include media-min($md){
                width: auto;
                padding-left: rem(48px);
                padding-right: rem(48px);
            }
        }

        &>span {
            font-size: rem(14px);
            color: #4f5330;
            font-weight: 600;
        }

        &>h3 {
            font-size: rem(24px);
            font-weight: 600;
            color: #000000;
            text-transform: uppercase;
        }

        &>p {
            font-size: rem(16px);
            font-weight: 400;
            color: #ffffff;
            text-align: justify;
            // max-width: rem(562px);
            // max-width: 40%;
        }

        &-gallery {
            max-height: rem(250px) !important;
            min-height: rem(250px) !important;

            & > div > ul {
                min-height: rem(250px) !important;
                max-width: rem(560px);

                @include media-min($sm) {
                    max-width: 100%;
                }

                @include media-min($lg) {
                    max-width: rem(560px);
                }
            }

            & > div >ul>li {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                // margin-left: rem(37px);
                // justify-content: flex-start;
                max-height: rem(250px) !important;

                // justify-content: center;


                @include media-min($sm){
                    // width: 100vw;
                    justify-content: center;
                    margin: 0;
                }

                @include media-min($md){
                    max-height: rem(250px) !important;
                    // margin-left: rem(210px);
                    // justify-content: flex-start;
                }

                @include media-min($lg){
                    justify-content: center;
                    // margin-left: rem(110px);
                }

                & > img {
                    max-width: rem(302px);
                    height: auto;
                    margin-left: rem(33px);

                    @include media-min-max($xsm, $sm){
                        margin-left: rem(130px);
                    }

                    @include media-min($sm){
                        margin-left: rem(0px);
                        max-width: rem(350px);
                    }

                    @include media-min($md){
                        max-width: rem(400px);
                    }

                    @include media-min($lg) {
                        max-width: rem(350px);
                    }
                }
            }

            & > a:nth-of-type(2) {
                position: absolute;
                left: 86%;
                // bottom: rem(200px);
                bottom: rem(270px);

                @include media-min-max($xsm, $sm){
                    left: 81%;
                }

                @include media-min($sm){
                    position: relative;
                    left: 80%;
                    bottom: rem(140px);
                }
                @include media-min($md){
                    position: absolute;
                    left: 83%;
                    bottom: 43%;
                }
                @include media-min($lg){
                    position: absolute;
                    left: rem(500px);
                    bottom: 29%;
                }
            }
            & > a:first-of-type {
                position: absolute;
                left: 2%;
                // bottom: rem(200px);
                bottom: rem(270px);

                @include media-min-max($xsm, $sm){
                    left: 15%;
                }

                @include media-min($sm){
                    position: relative;
                    left: 16%;
                    bottom: rem(140px);    
                }
                @include media-min($md){
                    position: absolute;
                    left: 14%;
                    bottom: 43%;
                }
                @include media-min($lg){
                    left: rem(60px);
                    bottom: 29%;
                }
               
                & > img {
                    transform: rotate(180deg);
                }
            }
        }

        &-nav {
            position: absolute;
            z-index: 5;
            top: 0;
            left: 92%;
            min-height: 100%;
            list-style: none;
            padding: 0;
            margin: 0;
            padding-top: rem(180px);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            @include media-min($sm){
                padding-top: rem(55px);
            }

            @include media-min($md) {
                left: 85%;
            }

            @include media-min($lg) {
                padding-top: rem(55px);
                left: 35%;
                transform: translateX(-100%);
            }

            .uk-active a {
                color: $white;
                opacity: 1;
                font-weight: 600;
            }
        }

        &-navItem {
            font-size: rem(10px);
            width: rem(30px);
            height: rem(70px);

            @include media-min($md) {
                font-size: rem(12px);
                width: rem(60px);
            }

            a {
                color: #000000;
                font-weight: 700;
                text-align: left;
                position: relative;
                width: 100% !important;
                height: rem(60px);
                display: block;
                opacity: 0.6;

                @include media-min($md) {
                    height: rem(60px);
                }

                // @include media-min($xl) {
                //     height: rem(100px);
                // }

                &:hover {
                    opacity: 1;
                    color: $white;
                }

                img {
                    // display: none;
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0.5s linear, opacity 0.5s linear;
                    position: absolute;
                    height: rem(39px);
                    right: 100%;
                    transform: translate(-70%, -32%);

                    @include media-min($sm){
                        // visibility: hidden;
                        // opacity: 0;
                        // position: absolute;
                        // height: rem(39px);
                        // right: 100%;
                        // transform: translate(-70%, -32%);
                        // transition: visibility 0.5s linear, opacity 0.5s linear;
                    }

                    @include media-min($md) {
                        height: rem(39px);
                    }

                    @include media-min($xl) {
                        height: rem(39px);
                    }
                }
            }

        }

        &-features {
            padding-left: rem(17px);
            padding-top: rem(20px);
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            height: rem(80px);

            @include media-min($sm){
                justify-content: center;
            }

            @include media-min($md) {
                padding-left: rem(0px);
                padding-top: rem(50px);
            }

            @include media-min($lg){
                padding-left: rem(80px);
                padding-top: rem(20px);
                justify-content: flex-start;
            }

            & > div {
                display: grid;
                grid-template-rows: rem(50px) rem(10px);
                // margin-right: rem(40px);
                margin-right: rem(20px);

                & > img {
                    // max-width: rem(40px);
                    max-width: rem(50px);
                }

                & > span {
                    // font-size: rem(12px);
                    font-size: rem(10px);
                }
            }
        }

        &-actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            padding-left: rem(58px);
            padding-right: rem(58px);
            padding-top: rem(40px);

            & > a {
                font-size: rem(16px);
                color: #000000;
                text-transform: uppercase;
                text-decoration: underline;
                font-weight: 400;
            }
        }
    }

    &__area {
        display: none;

        @include media-min($lg) {

            // wymuszenie aspect ratio 16x9
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            // koniec aspect ratio 16x9

            background-position: right top;
            background-repeat: no-repeat;
            background-size: 75%;
            background-image: url('../assets/images/rsz_product-map.png');
            z-index: 2;
            display: block;
            position: absolute;
            width: 100%;

            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
        }
    }

    &__img {
        position: absolute;
        width: auto;
        display: flex;
        justify-self: flex-end;

        @include media-min($sm) {}

        @include media-max($md) {}

        @include media-min($md) {
            position: relative;
            margin-right: rem(-200px);
            margin-top: rem(-50px);

            max-height: rem(1050px);
            // margin-right: rem(-150px);
            // margin-top: rem(-80px);

            // max-height: rem(990px);
            width: auto;
        }

        @include media-min($lg) {
            // max-height: 42.5rem;
            // margin-right: rem(229px);
            // margin-top: rem(-15px);

        }
    }

    &__zoom {
        position: relative;
        background-color: #c65827;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;

        min-height: rem(210px);
        min-width: rem(260px);
        margin-left: rem(10px);
        margin-right: rem(50px);
        margin-bottom: rem(30px);

        @include media-min-max($xsm, $sm){
            max-width: rem(358px);
            margin-left: rem(100px);
        }

        @include media-min($sm){
            min-height: rem(250px);
            max-width: rem(410px);
            margin-left: rem(140px);
            margin-top: rem(20px);
        }

        @include media-min($md){
            min-height: rem(290px);
            min-width: rem(540px);
        }

        @include media-min($lg){
            width: auto;
            min-height: rem(250px);
            // margin: rem(20px);
            margin: rem(20px) auto;
            min-width: rem(410px);
        }

        &:before {
            content: '';
            position: absolute;
            top: -1px;
            left: -1px;
            border: 1px solid #c65827;
            background-color: #c65827;

            min-height: rem(210px);
            min-width: rem(358px);
            border-radius: 8%;

            @include media-min($sm) {
                min-height: rem(250px);
                min-width: rem(410px);
                border-radius: 8%;
            }

            @include media-min($md) {
                min-height: rem(290px);
                min-width: rem(540px);
                border-radius: 12%;
            }

            @include media-min($lg) {
                min-height: rem(250px);
                min-width: rem(410px);
                border-radius: 8%;
            }
        }

        &>img {
            max-height: rem(190px);
            width: auto;
            object-fit: cover;
            z-index: 2;

            @include media-min($sm) {
                max-height: rem(220px);
            }

            @include media-min($md) {
                max-height: rem(255px);
            }

            @include media-min($lg){
                max-height: rem(220px);
            }
        }
    }

    &__circle {
        position: absolute;
        width: rem(28px);
        height: rem(28px);
        background-color: #f16322;
        border-radius: 100%;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &>span:first-of-type {
            top: 0;
            visibility: hidden;
            opacity: 0;
            transition: visibility .0s, opacity .3s linear;
        }

        &>span:nth-of-type(2) {
            z-index: 0;
            color: white;
        }

        &:nth-of-type(1) {
            @include media-min($lg) {
                left: 47%;
                top: 25%;
            }
        }

        &:nth-of-type(2) {
            @include media-min($lg) {
                left: 64%;
                top: 23%;
            }
        }

        &:nth-of-type(3) {
            @include media-min($lg) {
                left: 82%;
                top: 21%;
            }
        }

        &:nth-of-type(4) {
            @include media-min($lg) {
                left: 43%;
                top: 39%;
            }
        }

        &:nth-of-type(5) {
            @include media-min($lg) {
                left: 57%;
                top: 39%;
            }
        }

        &:nth-of-type(6) {
            @include media-min($lg) {
                left: 47%;
                top: 52%;
            }
        }

        &:nth-of-type(7) {
            @include media-min($lg) {
                left: 54%;
                top: 62%;
            }
        }
        &:nth-of-type(8) {
            @include media-min($lg) {
                left: 78%;
                top: 46%;
            }
        }
        &:nth-of-type(9) {
            @include media-min($lg) {
                left: 84%;
                top: 34%;
            }
        }
        &:nth-of-type(10) {
            @include media-min($lg) {
                left: 97%;
                top: 27%;          
            }
        }
        &:nth-of-type(11) {
            @include media-min($lg) {
                left: 62%;
                top: 78%;           
            }
        }
        &:nth-of-type(12) {
            @include media-min($lg) {
                left: 75%;
                top: 60%;         
            }
        }
    }

    &__circle {
        &-active {
            .map__cross:before, .map__cross:after, .map__cross-sec:after, .map__cross-sec:before { 
                visibility: visible;
                opacity: 0.6;
            }
    
            &>span:first-of-type {
                position: absolute;
                width: rem(69px);
                height: rem(69px);
                background-color: #f16322;
                box-shadow: 0 0 10px rgba(95, 40, 15, 0.4);
                border-radius: 100%;
                left: rem(-20px);
                transform: translateY(-30%);
                visibility: visible;
                opacity: 0.6;
            }
        }
    }

    &__cross {
        position: absolute;
        &:before {
            content: "";
            position: absolute;
            height: rem(2px);
            width: rem(17px);
            transform: translate(80%, 0%);
            background-color: white;
            opacity: 0;
            visibility: hidden;
            transition: visibility .0s, opacity .3s linear;
        }

        &:after {
            content: "";
            position: absolute;
            height: rem(17px);
            width: rem(2px);
            transform: translate(0%, 80%);
            background-color: white;
            opacity: 0;
            visibility: hidden;
            transition: visibility .0s, opacity .3s linear;
        }

        &-sec {
            &:before {
                content: "";
                position: absolute;
                height: rem(2px);
                width: rem(17px);
                transform: translate(-180%, -500%);
                background-color: white;
                opacity: 0;
                visibility: hidden;
                transition: visibility .0s, opacity .3s linear;
            }
    
            &:after {
                content: "";
                position: absolute;
                height: rem(17px);
                width: rem(2px);
                transform: translate(0%, -260%);
                background-color: white;
                opacity: 0;
                visibility: hidden;
                transition: visibility .0s, opacity .3s linear;
            }
        }
    }

    &__crossZoom {
        &:before {
            content: "";
            position: absolute;
            height: rem(2px);
            width: rem(30px);
            transform: translate(43%, 1350%);
            background-color: white;
        }

        &:after {
            content: "";
            position: absolute;
            height: rem(30px);
            width: rem(2px);
            transform: translate(1350%, 42%);
            background-color: white;
        }
    }
}