.solutionsPage{
    background: #2c2e19;
    color: $white;

    @include media-max($sm) {
        position: relative;
        // margin-top: rem(80px);
        margin-top: rem(150px);
        width: 100%;
    }

    @include media-min($sm) {
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        margin-top: rem(150px);
    }

    @include media-min($lg){
        // margin-top: rem(120px);
        margin-top: rem(185px);
    }

    &__row{
        @include media-max($sm) {
            // position: relative;
            // display: grid;
            // grid-template-columns: repeat(2, 1fr);
        }

        @include media-min($sm){
            grid-column: 1 / 6;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
        }

        

        &:nth-child(odd) {
            background-color: #33351e;

            @include media-max($sm) {
                & > div{
                    grid-template-areas: 
                    "description"
                    "img"
                    "readmore";
                }
            }

            @include media-min($sm){
                & > div{
                    grid-template-areas: 
                    "description img"
                    "readmore img";
                }
            }
        }
        &:nth-child(even) {
            background-color: #2c2e19;

            @include media-max($sm) {
                & > div{
                    grid-template-areas: 
                    "description"
                    "img"
                    "readmore";
                }
            }

            @include media-min($sm){
                & > div{
                    grid-template-areas: 
                    "img description"
                    "img readmore";
                }
            }

            @include media-min($sm){
                & > div .solutionsPage__description{
                    padding-left: rem(80px);
                }
    
                & > div .solutionsPage__btn{
                    margin-left: rem(55px);
                }
            }

            @include media-min($md){
                & > div .solutionsPage__description{
                    padding-left: rem(130px);
                }
    
                & > div .solutionsPage__btn{
                    margin-left: rem(95px);
                }
            }
        }
    }

    &__cnt{
        grid-column: 2 / 5;
        display: grid;

        @include media-max($sm){
            grid-template-columns: 1fr;
            grid-template-rows: auto;  
            position: relative;
        }

        @include media-min($sm){
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);       
            margin-top: rem(20px);
            grid-column: 1 / 6;
        }

        @include media-min($md){
            grid-column: 2 / 5;
        }
    }

    &__description{
        grid-area: description;
        padding-left: rem(38px);

        @include media-max($sm){
            padding-top: rem(10px);
        }

        span{
            font-size: rem(20px);
            font-weight: 700;

            @include media-max($sm){
                font-size: rem(16px);
            }
        }

        h1{
            font-size: rem(28px);
            font-weight: 700;
            text-transform: uppercase;

            @include media-max($sm){
                font-size: rem(26px);
            }
        }

        p{
            width: 70%;
            font-size: rem(18px);
            font-weight: 700;

            @include media-max($sm){
                font-size: rem(16px);
            }
        }
    }

    &__btn{
        grid-area: readmore;
        max-width: rem(280px);
        padding-top: rem(30px);
    }

    &__img{
        grid-area: img;
        padding-top: rem(10px);

        @include media-max($sm){
            display: flex;
            justify-self: center;
            max-width: rem(300px);
            margin-bottom: rem(40px);
        }

        @include media-min($sm){
            max-height: rem(250px);
            max-width: rem(350px);
        }

        @include media-min($md){
            max-height: rem(250px);
            max-width: rem(350px);
        }

        @include media-min($lg){
            max-height: rem(335px);
            max-width: rem(531px);
        }
    }
}