.documents{
    background: #fffffa;
    color: #33351e;
    margin: -2px 0;

    @include media-min($md) {
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        // grid-template-rows: repeat(2, 1fr);
        grid-template-rows: rem(80px) 1fr;
        grid-template-areas: 
        ". title title title . "
        ". cnt cnt cnt .";
    }

    &__title {
        margin: 0 auto;
        width: 80%;
        position: relative;
        // padding: 0 rem(40px);
        padding: 0 rem(46px);
        padding-top: rem(20px);
        border-left: 1px solid $borderColorLight;
        grid-area: title;

        @include media-min($md) {
            grid-row: 1;
            grid-column: 2 / 5;
            margin: 0;
            width: 100%;
        }

        h2 {
            margin: 0;
            font-weight: 400;
            font-size: rem(24px);

            @include media-min($md) {
                font-size: rem(24px);
            }
        }

        img {
            transform: translateX(-50%);
            left: 0;
            position: absolute;
            // top: rem(20px);
            top: rem(12px);
            grid-column: 2 / 2;
            z-index: 1;
            max-height: rem(60px);

            @include media-min($md) {
                // max-height: rem(90px);
                max-height: rem(60px);
            }

            // @include media-min-max($sm, $md){
            //     left: rem(-15px);
            // }

            @include media-min($xl) {
                max-height: rem(70px);
            }
            
        }
    }

    &__cnt{
        // padding-top: rem(20px);
        margin-left: rem(40px);
        padding-bottom: rem(39px);
        grid-area: cnt;
        border-left: 1px solid $borderColorLight;

        @include media-max($lg){
            display: flex;
            flex-direction: column;
            padding-top: rem(0px);

            margin: 0 auto;
            width: 80%;
        }

        @include media-min-max($sm, $md){
            // padding-left: rem(60px);
            margin-left: rem(70px);
            padding-right: rem(60px);
        }

        @include media-min($md) {
            margin-left: 0;
            padding-left: rem(46px);
        }

        @include media-min($lg) {
            max-width: 80%;
        }

        div:first-child{
            grid-column: 1 / 1;
        }

        div:first-child p{
            width: 80%;
        }
    }

    &__pdf{
        display: flex;
        flex-direction: row;
        grid-column: 2 / 2;
        flex-wrap: wrap;


        @include media-max($lg){
            width: 90%;
            align-self: center;
            margin-left: rem(50px);
            margin-top: rem(20px);
        }

        a{
            cursor: pointer;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: rem(57px);
            height: rem(56px);
            background-color: #fffffa;
            border-radius: rem(10px);
            box-shadow: 0 rem(5px) rem(10px) rgba(0, 0, 0, 0.1);

            @include media-max($lg){
                max-width: rem(150px);
                margin-bottom: rem(20px);
            }
        }

        a:not(:last-child){
            margin-right: rem(20px);
        }

        @include media-max($lg){
            a:last-child{
                margin-right: rem(20px);
            }
        }

        a img{
            max-width: rem(25px);
        }
    }

    &__elem {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: rem(15px);

        & > span {
            align-self: center;
            font-size: rem(12px);
            font-weight: 400;
            max-width: rem(100px);
        }
    }
}