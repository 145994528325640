.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  &__item {
  }
  &__link {
    display: block;
    padding: rem(0 7px);
    font-size: rem(18px);
    font-weight: 700;
    margin: rem(0 2px 2px);
    &--active {
      background-color: red;
      color: white;
      pointer-events: none;
    }
    &--prev {
      &::before {
        content: "<";
        display: inline-block;
        font-size: .6em;
      }
    }
    &--next {
      &::before {
        @extend .pagination__link--prev::before;
        content: ">";
      }
    }
  }
}
