.footer {
  background: #1d201b;
  padding-bottom: rem(50px);

  @include media-min($sm) {
    display: grid;
    // grid-template-columns: 1fr 3fr 1fr;
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 1fr auto;
  }

  &__logo {
    width: 80%;
    margin: 0 auto;
    margin-top: rem(40px);
    padding-bottom: rem(40px);
    border-bottom: 1px solid $borderColor;

    @include media-min($sm) {
      grid-row: 1;
      grid-column: 2 / 3;
      margin: 0;
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: rem(35px);
    }

    img {
      padding: (5px 10px);
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: rem(20px);

    @include media-min($sm) {
      width: 70%;
    }


    @include media-min($md) {
      width: 100%;
      justify-content: flex-end;
    }
  }

  &__item {
    color: $white;
    font-weight: 700;
    text-transform: uppercase;

    @include media-min($md) {
      padding-right: rem(20px);
    }

    a {
      transition: 0.8s;
      font-size: rem(14px);

      @include media-min($md) {
        font-size: rem(19px);
      }

      &:hover {
        color: $secondary;
      }
    }
  }

  &__item {
    font-size: rem(18px);
    color: $white;
    font-weight: 700;

    a {
      transition: 0.8s;

      &:hover {
        color: $secondary;
      }
    }
  }

  &__info {
    width: 80%;
    margin: 0 auto;
    margin-top: rem(40px);
    color: #5d6756;
    font-size: rem(12px);


    @include media-min($sm) {
      grid-row: 2;
      grid-column: 2 / 3;
      margin: 0;
      display: flex;
      align-items: flex-start;
      margin-top: rem(35px);
      width: 100%;
    }

    ul {
      display: flex;
      justify-content: space-between;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: rem(20px 0);

      @include media-min($sm) {
        width: 60%;
      }

      @include media-min($md) {
        width: 40%;
      }

    }

    img {
      max-height: rem(17px);

      @include media-min($sm) {
        max-height: rem(12px);
      }
    }
  }

  &__copy,
  &__artneo {
    font-weight: 600;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 1.25rem;
  }

  &__artneo {

    @include media-min($sm) {
      width: 50%;
      display: flex;
      justify-content: flex-end;
    }
  }

}