.details {
    position: relative;
    background-color: #33351e;
    max-height: 100%;
    width: 100%;
    color: white;
    border-bottom: 1px solid $borderColor;

    @include media-max($sm) {
        z-index: 2;
    }

    @include media-min($sm) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }

    &__box {
        top: rem(-140px);
        min-height: rem(150px);

        z-index: 10;
        background-color: #505331;
        position: absolute;
        width: 100%;

        @include media-min($sm){
            margin-left: 5.5%;
        }

        @include media-min($md){
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-top: rem(17px);
            margin-left: 0;
        }

        @include media-max($sm) {
            max-width: 100%;
            margin-left: 0 !important;
            height: auto;
        }

        @include media-max($md) {
            width: rem(654px);
            // margin-left: rem(73px);
            padding-left: rem(30px);
        }

        @include media-min($md) {
            grid-column: 2 / 5;
            padding-left: rem(50px);
            top: -9.5%;
        }
        
    }

    &__arrow {
        padding-top: rem(20px);
        max-width: rem(41px);

        @include media-max($sm) {
            margin-left: rem(50px);
            margin-top: rem(35px);
        }

        @include media-min($md) {
            grid-area: arrow;
        }
    }

    &__logo {
        display: flex;
        align-self: end;
        padding-bottom: rem(41px);
        max-width: rem(130px);
        opacity: 0.59;

        @include media-max($sm) {
            margin-left: rem(55px);
        }

        @include media-min($md) {
            grid-area: logo;
        }
    }

    &__title {
        margin-top: rem(20px);

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        width: initial;
        padding-right: rem(25px);
        padding-bottom: 1rem;

        @include media-min($sm) {
            justify-content: end;
            margin-top: rem(20px);
        }

        @include media-min($md) {
            grid-area: title;
            justify-content: flex-end;
            margin-top: 0;

            width: 70%;
        }

        @include media-min($lg) {
            width: 60%;
            padding-bottom: rem(20px);
        }

        &-cnt {
            margin-left: rem(20px);

            @include media-min($sm) {
                margin-top: rem(13px);
            }

            @include media-min($md) {
                margin-left: 0;
            }

            @include media-min($lg) {
                margin-top: 13px;
                width: 76%;
            }

            &>h2 {
                font-family: 'montserrat';
                font-weight: 700;
                margin-bottom: rem(10px);

                @include media-min($md) {
                    font-size: rem(27px);
                    line-height: rem(44px);
                }

                @include media-min($lg) {
                    font-size: rem(32px);
                }
            }

            &>p {
                font-weight: 500;
                font-size: rem(24px);
                line-height: 1.3;
                margin-bottom: rem(10px);

                @include media-min($md) {
                    font-size: rem(20px);
                }

                @include media-min($lg) {
                    font-size: rem(24px);
                }
            }
        }
    }

    &__cnt {
        position: relative;
        width: initial;
        min-height: rem(298px);
        margin: 0;
        color: white;
        font-weight: 300;
        border-right: rem(1px) solid #60614f;

        padding-right: rem(38px);
        padding-left: rem(38px);
        padding-top: rem(70px);
        margin-bottom: rem(80px);

        @include media-min($sm) {
            grid-column: 1 / 6;
            margin-bottom: rem(100px);
            padding-right: rem(20px);
            margin-left: rem(50px);

            padding-top: rem(70px);
        }

        @include media-min($md) {
            grid-column: 2 / 5;
            margin-bottom: rem(50px);
            margin-left: 0;
        }

        @include media-min($lg) {
            padding-top: rem(120px);
            grid-column: 2 / 6;
        }

        &-title {
            font-weight: 500;
        }

        &-desc {
            @include media-min($lg) {
                width: 26%;
            }
        }

        &-descLong {
            @include media-min($lg) {
                width: 33%;
            }
        }

        &-clients {
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    &__worldMap {
        display: none;

        @include media-min($lg) {
            top: 0;
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: flex-end;
            padding-top: rem(147px);
            z-index: 1;
            pointer-events: none;

            &-cnt {
                max-width: 72%;
                height: auto;
                pointer-events: initial;
            }
        }
    }

    &__list {
        @include list-clear;
        margin-bottom: rem(40px);
        margin-top: rem(20px);
        margin-left: rem(38px);

        @include media-max($sm) {
            margin-top: rem(40px);
            margin-left: rem(23px);
            margin-right: rem(8px);
            line-height: rem(31px);
        }

        &-elem:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 0.5625rem;
            height: 0.5625rem;
            background-color: #f16322;
            margin-top: rem(7px);
            margin-left: rem(0px);

            @include media-max($md) {
                left: rem(40px);
            }

            @include media-min($md) {
                margin-left: rem(60px);
            }
        }
    }

    &__description {
        font-size: rem(21px);
        padding-left: rem(47px);

        @include media-max($sm) {
            font-size: rem(16px);
        }

        @include media-min($sm) {
            &--top {
                grid-area: topInfo;
            }

            &--bottom {
                grid-area: bottomInfo;
            }
        }
    }

    &__arrow {
        display: none;


        @include media-min($md) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: rem(100px);

            img {
                animation: mover 1s infinite alternate;
                height: rem(21px);
            }
        }
    }

    &__quote {
        @include media-max($sm) {
            position: absolute;
            margin: 0;
            bottom: rem(-70px);
            right: 1.25rem;
            font-size: 0.75rem;
            font-weight: 600;
            background: #1d201b;
            color: #fff;
            max-width: rem(240px);
            padding: 1.25rem 0.8125rem;
            transform: translateY(50%);
            font-style: normal !important;
        }
    }

    &__clients {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        z-index: 1;

        @include media-min($sm) {
            justify-content: unset;
        }

        &-logo {
            width: rem(140px);
            max-height: rem(140px);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: rem(50px);

            @include media-min($sm) {
                margin: rem(11px);
            }

            @include media-min($md) {
                width: rem(120px);
                max-height: rem(120px);
            }

            @include media-min($md) {
                width: rem(130px);
                max-height: rem(130px);
            }
        }
    }
}