.breadcrumbs {
    display: grid;
    position: relative;
    border-top: 2px solid #60614f;
    background-color: #1e1f11;
    width: 100%;
    min-height: rem(64px);
    max-height: rem(64px);
    color: #61634d;
    z-index: 20;
    position: fixed;

    &.sticky {
        background-color: #1e1f11;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.5s;
    }

    @include media-max($sm) {
        top: rem(84px);
    }

    @include media-min($sm) {
        top: rem(85px);
        padding-left: 0;
    }

    @include media-min($md) {
        top: 75px;
    }

    @include media-min($md) {
        padding-left: 0;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @include media-min($lg) {
        grid-column: 2 / 5;
        top: rem(121px);
    }

    @include media-max($lg) {
        display: flex;
    }

    &--transparent {
        @extend .breadcrumbs;
        background-color: transparent;
        border-bottom: rem(1px) solid #60614f;

        &.sticky {
            background-color: #1e1f11;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            transition: background-color 0.3s;
        }

        @include media-max($sm) {
            &:not(.easibridge){
                background-color: #1e1f11;
            }
        }

        &__cnt {
            @include media-min($md) {
                grid-column: 2 / 5;
            }
        }
    }

    @include media-min($lg) {
        &__cnt {
            border-left: 1px solid $borderColor;
            border-right: 1px solid $borderColor;
            grid-column: 2 / 5;
            display: flex;
            align-items: center;
        }
    }

    &__elem {
        &-basic {
            color: white;
        }

        &-last {
            color: #565846;
        }
    }

    &__list {
        margin-top: rem(20px);
        display: flex;
        list-style: none;
        text-decoration: none;
        text-transform: uppercase;
        font-size: rem(12px);
        font-weight: 600;

        @include media-max($sm) {
            display: flex;
            align-items: flex-start;
            padding-left: rem(3px);
            font-size: rem(13px);
        }

        &-arrow {
            transform: rotate(90deg);
        }

        li {
            padding: 0 rem(10px) 0 rem(10px);

            &>i {
                color: #565846;
            }
        }

        &-storage {
            color: #bfa48a;
        }

        &-rackbox {
            color: #8B7B9E;
        }

        &-training {
            color: #e57273;
        }

        &-ramps {
            color: #9CD7D5;
        }

        &-camps {
            color: #D9A78B;
        }

        &-tents {
            color: #F1C333;
        }

        &-portable_equipment {
            color: #82C39B;
        }
    }

    &>div:first-of-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-right: rem(63px);

        @include media-max($sm) {
            display: none;
        }

        @include media-min($sm) {
            padding-left: rem(10px);
            padding-right: rem(10px);
        }

        @include media-min($lg) {
            padding-right: rem(30px);
        }

        &>i {
            font-size: rem(30px);
        }
    }

    &__icon {
        &>span {
            font-size: rem(18px);
            font-weight: 600;
            color: #1e1e10;
            text-transform: uppercase;

            @include media-min($sm) {
                margin-right: rem(10px);
            }

            @include media-min($lg) {
                margin-right: rem(30px);
            }
        }

        svg {
            max-height: 32px;
        }

        & > span:nth-of-type(2){
            min-width: rem(40px);
        }

        & > span:nth-of-type(2){
            & > svg > path {
                fill: inherit !important;
            }
        }

        &-easibridge{
            background-color: #4d88342d;
            color: white;
            
            & > span {
                color: white;
            }
        }

        &-storage {
            background-color: #bfa48a80;
            color: #bfa48a;
        }

        &-rackbox {
            background-color: #8b7b9e80;
            color: #8B7B9E;
        }

        &-training {
            background-color: #e5727481;
            color: #e57273;
        }

        &-ramps {
            background-color: #9cd7d581;
            color: #9CD7D5;
        }

        &-camps {
            background-color: #d9a88b80;
            color: #D9A78B;
        }

        &-tents {
            background-color: #f1c2337e;
            color: #F1C333;
        }

        &-portable_equipment {
            background-color: #82c39b85;
            color: #82C39B;
        }
    }
}
