@mixin zindex {
  @for $i from 1 through 6 {
    .item_#{$i} {
      z-index: (7 - $i) !important;
    }
  }
}


.categories {
  background: #2a2c17;
  color: $white;

  margin-top: rem(140px);

  @include media-min($md) {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr auto;

    margin-top: rem(160px);
  }

  &__title {
    margin: 0 auto;
    width: 80%;
    position: relative;
    padding: 0 rem(40px);
    padding-top: rem(50px);
    border-right: 1px solid $borderColor;
    padding-bottom: rem(24px);

    @include media-min($md) {
      grid-row: 1;
      grid-column: 2 / 5;
      margin: 0;
      width: 100%;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background: $borderColor;

      @include media-min($sm) {
        left: rem(-15px);
      }

      @include media-min($md) {
        left: 0;
      }
    }

    h1 {
      font-weight: 400;
      font-size: rem(24px);
      margin: 0;

      @include media-min($md) {
        font-size: rem(24px);
      }
    }

    img {
      position: absolute;
      max-height: rem(60px);
      left: 0;
      top: 40%;
      transform: translateX(-50%);

      @include media-min($sm) {
        left: rem(-15px);
      }

      @include media-min($md) {
        left: 0;
        max-height: rem(65px);
      }
      @include media-min($xl) {
        left: 0;
        max-height: rem(87px);
      }
    }

    p {
      @include media-min($md) {
        font-size: rem(22px);
      }
    }
  }

  &__cnt {
    width: 80%;
    margin: rem(0 auto 0 auto);

    @include media-min($md) {
      grid-row: 2;
      grid-column: 2 / 5;
      margin: 0;
      width: 100%;
      z-index: 10;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-min($sm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-min($md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    position: relative;
    border-right: 1px solid $borderColor;
    border-left: 1px solid #60614fa3;

    &:nth-of-type(2n + 1) {
      @include media-min($sm) {
        &:nth-of-type(2n + 1) {
          border-right: 1px solid #60614fa3;
        }
      }
    }

    &:nth-child(odd) {
      background-color: #2f321d;
    }
    &:nth-child(even) {
      background-color: #2b2b18;
    }

    @include media-min($md) {
      border-right: none;

      &:nth-of-type(3n) {
        border-right: 1px solid #60614fa3;
      }
    }

    img {
      width: 100%;
      height: rem(227px);
    }

    &-title {
      height: rem(55px);
      display: flex;
      align-items: flex-end;
      justify-content: center;

      h2 {
        font-size: rem(18px);
        font-weight: 700;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      img {
        background-size: contain;
        max-width: rem(40px);
        max-height: rem(20px);
        margin-right: rem(15px);
      }
    }

    >p {
      width: 90%;
      color: #45482e;
      font-size: rem(18px);
      font-weight: 400;
      margin: 0;
      padding-bottom: rem(40px);
      margin-left: rem(30px);
    }

    a {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  &__hover {
    position: absolute;
    height: rem(45px);
    width: rem(45px);
    text-align: center;
    line-height: rem(45px);
    bottom: rem(5px);
    left: rem(15px);
    font-size: rem(30px);
    font-weight: 100;
    z-index: 2;

    @include media-min($md) {
      z-index: 0;
    }

    &:hover {
      color: $secondaryDark;
    }
  }

  &--box {
    display: none;

    @include media-min($md) {
      display: block;
      position: absolute;
      height: 20%;
      width: 10.5%;
      border: 1px solid #60614f;
      border-top: none;
      right: 0;
      top: 25%;
    }
  }

  &--boxSmall {
    display: none;

    @include media-min($md) {
      display: block;
      position: absolute;
      height: 20%;
      width: 10.5%;
      border: 1px solid #60614f;
      right: 0;
      bottom: 13%;
    }
  }

  &--boxRight,
  &--boxSmallLeft,
  &--boxLeft {
    display: none;

    @include media-min($md) {
      display: block;
      position: absolute;
    }
  }

  &--boxRight {
    @include media-min($md) {
      bottom: 0%;
      right: 10.4%;
      border: 1px solid #60614f;
      height: 75%;
      width: 11.5625rem;
      border-right: none;
      border-bottom: none;
    }
  }

  &--boxSmallLeft {
    @include media-min($md) {
      bottom: 5%;
      left: 0;
      border: 1px solid #60614f;
      height: 24%;
      width: 11.5625rem;
    }
  }

  &--boxLeft {
    @include media-min($md) {
      bottom: 0;
      left: 0;
      border-right: 1px solid $borderColor;
      height: 36%;
      width: 21rem;

      &::after {
        content: "";
        position: absolute;
        width: rem(1px);
        height: 200%;
        background: $borderColor;
        transform: translateY(-100%) rotate(-66deg);
        right: 0;
        transform-origin: bottom right;
      }
    }
  }
}

@include zindex;