.hero {
  &__slider {
    position: relative;

    &-list {}

    &-lines {
      display: none;

      @include media-min($md) {
        pointer-events: none;
        position: absolute;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 100vh;
        width: 1px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        z-index: 4;

        span:nth-of-type(2) {
          display: flex;
          align-items: flex-end;
        }

        span:nth-of-type(2),
        span:nth-of-type(3) {
          position: relative;

          &::before, 
          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 26%;
            background: $borderColor;
            right: 0;
          }

          &::before  {
            top: rem(122px);
          }

          &::after  {
            bottom: 0;
          }
        }

        span:nth-of-type(4){
          border-right: 1px solid $borderColor;
        }
      }
    }

    &-item {}
    &-img {
      object-fit: cover;
      min-height: 100% !important;
      min-width: 100%;
      z-index: 1;
      height: auto !important;
    }
    &-cnt {
      z-index: 3;
      color: $white;
      font-size: rem(16px);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 100%;

      align-items: flex-start;
      margin-left: 20%;

      @include media-min($sm) {
        align-items: flex-start;
        padding-left: 20%;
        margin-left: rem(50px);
        margin-top: rem(160px)
      }
    }

    &-heading {
      color: $white;
      font-weight: 700;
      max-width: rem(300px);
      font-size: rem(30px);
      text-transform: uppercase;
      margin-bottom: rem(0px);

      @include media-min($md) {
        font-size: rem(48px);
        max-width: 60%;
        word-break: break-word;

        letter-spacing: rem(3.32px);
      }
    }

    &-subtext {
      color: $white;
      font-weight: 700;
      max-width: rem(300px);
      font-size: rem(28px);
      text-transform: uppercase;
      margin-top: rem(0px);

      @include media-min($md) {
        max-width: 70%;
        word-break: break-word;
        font-size: rem(35px);
        letter-spacing: rem(0.1px);
      }
    }

    &-nav {
      position: absolute;
      z-index: 5;
      top: 0;
      left: rem(12px);
      min-height: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      padding-top: rem(100px);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      border-right: rem(1px) solid $borderColor;

      @include media-min($sm) {
        left: rem(28px);
      }

      @include media-min($md) {
        left: 20%;
        transform: translateX(-100%);
      }
      
      .uk-active a {
        opacity: 1;
        font-weight: 600;
        
        img {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &-navItem {
      font-size: rem(10px);
      width: rem(30px);
      counter-increment: step;

      @include media-min($md) {
        font-size: rem(12px);
        width: rem(60px);
      }
      
      a{
        color: $white;
        text-align: left;
        position: relative;
        width: 100% !important;
        height: rem(60px);
        display: block;
        opacity: 0.6;

        @include media-min($md) {
          height: rem(120px);
        }
          &:after {
            @include media-min($md) {
              content: "0"counter(step);
              position: absolute;
              left: 68vw;
            }
            @include media-min($lg) {
              left: 65vw;
            }
          }


        @include media-min($xl) {
          height: rem(100px);
        }

        &:hover {
          opacity: 1;
        }

        img {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          height: rem(80px);
          left: 100%;
          transform: translate(-47%, -50%);
          transition:visibility 0.5s linear, opacity 0.5s linear;

          @include media-min($md) {
            height: rem(126px);
          }

          @include media-min($xl) {
            height: rem(115px);
          }
        }
      }

    }
  }
}
