.slider {
    background-color: #1e1f11;
    color: $white;
    margin-top: rem(140px);
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__overlay {
        width: inherit;
        height: inherit;
        background-color: #0f0e0e;
        opacity: 0.54;
        height: 50vh;
    }

    &__slider {
        position: relative;
        height: inherit;
        display: flex;
        flex-direction: column;

        &-list {
            min-height: 75vh !important;
        }

        &-lines {
            display: none;

            @include media-min($md) {
                pointer-events: none;
                position: absolute;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: 100%;
                width: 1px;
                width: 100%;
                height: 100%;
                overflow: hidden;
                top: 0;
                left: 0;
                z-index: 4;

                span:nth-of-type(2) {
                    display: flex;
                    align-items: flex-end;
                }

                span:nth-of-type(2),
                span:nth-of-type(3) {
                    position: relative;

                }

                span:nth-of-type(4) {
                    border-right: 1px solid $borderColor;
                }
            }

            &-smaller {
                span:nth-of-type(4) {
                    height: 75vh !important;
                }
            }
        }

        &-bottomArea {
            position: relative;
            height: 100%;
            background-color: #33351e;

            @include media-min($md) {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: 100%;
            }
        }

        &-desc {
            border: 1px solid $borderColor;
            height: inherit;
            padding-top: rem(50px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include media-min($md) {
                grid-row: 1;
                grid-column: 2 / 5;
                position: relative;
            }

            @include media-min($lg) {
                grid-column: 2 / 4;
            }

            &>p {
                padding-left: rem(51px);
                font-weight: 400;
            }
        }

        &-admission {
            font-weight: 400;
            color: #ffffff;
            padding-left: rem(51px);
            padding-right: rem(100px);
        }

        &-content {
            font-weight: 400;
            color: #000000;
            padding-left: rem(51px);
            padding-right: rem(100px);
        }

        &-title {
            font-family: 'Banair';
            position: absolute;
            padding-left: rem(51px);
            background-color: $primaryLight;
            top: rem(-120px);

            min-height: rem(70px);
            padding-top: rem(10px);
            padding-bottom: rem(10px);
            width: 100vw;
            top: rem(-69px);
            display: flex;
            flex-direction: row;
            align-items: center;

            @include media-min($sm) {
                width: max-content;
                padding-right: 50px;
                top: -53px;
            }

            @include media-min($md) {
                width: 100%;
            }

            @include media-min($lg) {
                top: rem(-55px);
                width: 39.8vw;
            }

            &>h1 {
                text-transform: uppercase;
                font-weight: 400;
                font-size: rem(26px);

                margin-bottom: 0;

                &>span {
                    color: $secondaryLight;
                    font-weight: 700;
                }
            }

            &>img {
                height: rem(40px);
                margin-left: rem(10px);
            }
        }

        &-item {}

        &-img {
            object-fit: cover;
            object-position: center;
            min-width: 100%;
            min-height: 100%;

            @include media-min($sm) {
                transform: translate(-50%, -50%) !important;
            }
        }

        &-cnt {
            z-index: 3;
            color: $white;
            font-size: rem(16px);
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            min-height: 100%;

            @include media-min($sm) {
                align-items: flex-start;
                padding-left: 20%;
                margin-left: rem(50px);
                margin-top: rem(50px)
            }
        }

        &-heading {
            color: $white;
            font-weight: 700;
            max-width: rem(300px);
            font-size: rem(30px);

            @include media-min($md) {
                font-size: rem(60px);
                max-width: 60%;
                word-break: break-word;
            }
        }

        &-subtext {
            color: $white;
            font-weight: 300;
            max-width: rem(300px);
            font-size: rem(12px);

            @include media-min($md) {
                max-width: 40%;
                word-break: break-word;
            }
        }

        &-nav {
            position: absolute;
            z-index: 5;
            top: 0;
            left: rem(12px);
            min-height: 100%;
            list-style: none;
            padding: 0;
            margin: 0;
            padding-top: rem(180px);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            border-right: rem(1px) solid $borderColor;

            @include media-min($sm) {
                left: rem(10px);
            }

            @include media-min($md) {
                left: rem(28px);
                min-height: 97%;
            }

            @include media-min($md) {
                left: 20%;
                transform: translateX(-100%);
            }

            .uk-active a {
                opacity: 1;
                font-weight: 600;

                img {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &-navItem {
            font-size: rem(10px);
            width: rem(30px);
            counter-increment: step;

            @include media-min($md) {
                font-size: rem(12px);
                width: rem(60px);
            }

            a {
                color: $white;
                text-align: left;
                position: relative;
                width: 100% !important;
                height: rem(60px);
                display: block;
                opacity: 0.6;

                @include media-min($md) {
                    height: rem(120px);
                }

                &:after {
                    @include media-min($md) {
                        content: "0"counter(step);
                        position: absolute;
                        left: 68vw;
                    }

                    @include media-min($lg) {
                        left: 65vw;
                    }
                }


                @include media-min($xl) {
                    height: rem(100px);
                }

                &:hover {
                    opacity: 1;
                }

                img {
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    height: rem(80px);
                    left: 100%;
                    transform: translate(-47%, -50%);
                    transition: visibility 0.5s linear, opacity 0.5s linear;

                    @include media-min($md) {
                        height: rem(126px);
                    }

                    @include media-min($xl) {
                        height: rem(100px);
                    }
                }
            }

        }
    }

    &__moreCnt {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        position: fixed;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        z-index: 2;
        pointer-events: none;

        @include media-max($lg) {
            display: none;
        }
    }

    &__more {
        display: none;

        @include media-min($lg) {
            display: block;
        }

        height: rem(43px);

        width: fit-content;
        position: fixed;
        z-index: 2;
        bottom: 0;
        grid-column: 3 / 4;
        right: rem(6px);
        position: absolute;
        pointer-events: initial;

        &>span {
            height: rem(43px);
            width: rem(95px);
            background-color: #1f2013a3;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: rem(10px);

            &>a>img {
                animation: mover 1s infinite alternate;
            }
        }

        &-left {
            right: unset;
        }
    }
}