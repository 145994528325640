// ------------------------------------------------------------
// @include font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff);
// Use only woff2, woff formats, both are required
// Example: @include font-face('Font Name', $font-dir+'font-file-name', 400);
// @include font-face('Open Sans', $open-sans-dir+'OpenSans-Regular', 400);
// ------------------------------------------------------------
// Default fonts directory
$fonts-directory: '../assets/fonts/';
$font-dir:'font-dir/';
$montserrat-dir: 'montserrat/';
$banair-dir: 'banair/';
$icomoon-dir: 'icomoon/';

//Montserrat
@include font-face('Montserrat', $montserrat-dir + 'Montserrat-Light', 300);
@include font-face('Montserrat', $montserrat-dir + 'Montserrat-Regular', 400);
@include font-face('Montserrat', $montserrat-dir + 'Montserrat-Medium', 500);
@include font-face('Montserrat', $montserrat-dir + 'Montserrat-SemiBold', 600);
@include font-face('Montserrat', $montserrat-dir + 'Montserrat-Bold', 700);

//Banair
@include font-face('Banair', $banair-dir + 'BANAIR-ExtraBold', 700);

//icomoon
@font-face {
  font-family: 'icomoon';
  src:  url($fonts-directory + $icomoon-dir + 'icomoon.eot?ko4g9t');
  src:  url($fonts-directory + $icomoon-dir + 'icomoon.eot?ko4g9t#iefix') format('embedded-opentype'),
    url($fonts-directory + $icomoon-dir + 'icomoon.ttf?ko4g9t') format('truetype'),
    url($fonts-directory + $icomoon-dir + 'icomoon.woff?ko4g9t') format('woff'),
    url($fonts-directory + $icomoon-dir + 'icomoon.svg?ko4g9t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook-logo:before {
  content: "\e900";
}
.icon-instagram-logo:before {
  content: "\e901";
}
.icon-user:before {
  content: "\e902";
}
.icon-youtube-logo:before {
  content: "\e903";
}
.icon-arrow:before {
  content: "\ea50";
}
.icon-search:before {
  content: "\e986";
}
.icon-tents:before {
  color: #F1C333;
  content: "\e906";
}
.icon-ramps:before {
  color: #9CD7D5;
  content: "\e905";
}
.icon-portable_equipment:before {
  color: #82C39B;
  content: "\e904";
}
.icon-storage:before {
  color: #bfa48a;
  content: "\e907";
}
.icon-rackbox:before{
  color: #8B7B9E;
  content: "\e908";
}
.icon-camps:before{
  color: #D9A78B;
  content: "\e909";
}
.icon-training:before{
  color: #e57273;
  content: "\e912"
}
.icon-rapid:before{
  content: "\e90a";
}
.icon-deployable:before{
  content: "\e90c";
}
.icon-adjustable:before{
  content: "\e910";
}
.icon-space_saving:before{
  content: "\e90e";
}
