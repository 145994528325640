.banner {
    background-color: #1e1f11;
    color: $white;
    // margin-top: rem(60px);
    margin-top: rem(60px);
    position: relative;
    align-items: center;
    justify-content: center;
    transform: translateY(rem(-22px));
    height: rem(624px);

    @include media-min($md){
        margin-top: rem(100px);
    }

    &__picture{
        img{
            object-fit: cover;
            object-position: center;
            min-width: 100%;
            min-height: 100%;
            height: rem(608px);

            @include media-min($xl) {
                height: rem(608px)
            }

            @include media-min($xxl) {
                height: rem(628px);
            }
        }
    }

    &__slider {
        position: relative;
    
        &-list {}
    
        &-lines {
            display: none;
    
            @include media-min($lg) {
                position: absolute;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                width: 1px;
                width: 100%;
                height: 104%;
                overflow: hidden;
                top: 0;
                left: 0;
                z-index: 4;

    
                span:nth-of-type(2),
                span:nth-of-type(3) {
                    position: relative;
                    margin-top: rem(57px);
                    &::before, 
                    &::after {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 0;
                        background: $borderColor;
                        right: 0;
                    }
        
                    &::before  {
                        // top: rem(65px);
                        top: rem(25px);
                        height: 42%;
                    }
            
                    &::after  {
                        bottom: 0;
                    }
                }
    
                span:nth-of-type(4){
                border-right: 1px solid $borderColor;
                }
            }
        }

        &-nav {
            position: absolute;
            z-index: 5;
            // z-index: 1;
            top: 0;
            left: rem(45px);
            min-height: 100%;
            list-style: none;
            padding: 0;
            margin: 0;
            padding-top: rem(100px);
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            border-right: rem(1px) solid $borderColor;
            @include media-max($sm) {
                left: rem(45px);
                z-index: 0;
            }

            @include media-min($sm){
                left: rem(70px);
            }

            @include media-min($md) {
                left: 20%;
                transform: translateX(-100%);
            }
            
            .uk-active a {
                opacity: 1;
                font-weight: 600;
                img {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}