.features {
  background: #fffffa;
  color: #000000;
  position: relative;
  margin: -2px 0;

  @include media-min($md) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr auto;
  }

  &__cntAll {
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $borderColorLight;

    @include media-min($md) {
      flex-direction: row;
      grid-row: 1;
      grid-column: 2 / 5;
    }
  }

  &__title {

    padding: 0 rem(40px);
    width: 80%;
    margin: 0 auto;
    border-left: 1px solid #e2e3db;
    position: relative;
    padding-top: rem(50px);

    @include media-min($md) {
      width: 20%;
      padding: 0 rem(46px);
      padding-top: rem(50px);
      min-height: 100px;
      border: none;
    }

    h3 {
      margin: 0;
      font-weight: 400;
      font-size: rem(24px);

      @include media-min($md) {
        font-size: rem(24px);
      }
    }

    img {
      position: absolute;
      max-height: rem(60px);
      left: 0;
      top: rem(42px);
      transform: translateX(-50%);

      @include media-min($md) {
        max-height: rem(60px);
      }
    }

    p {
      @include media-min($md) {
        font-size: rem(22px);
      }
    }
  }

  &__cnt {
    z-index: 1;
    min-height: rem(220px);
    border-left: 1px solid #e2e3db;

    width: 80%;
    margin: 0 auto;

    @include media-min($md) {
      width: 100%;
      border: none;
      margin-top: rem(16px);
      padding-bottom: rem(52px);
    }
  }

  &__elementCnt {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-direction: row;
    flex-wrap: wrap;

    @include media-min($md) {
      flex-direction: row;
      align-items: unset;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    img {
      max-width: rem(86px);
      transition: transform .2s;
      grid-area: img;
      display: flex;
      justify-self: center;
    }

    h4 {
      font-size: rem(11px);
      font-weight: 400;
      text-transform: uppercase;
      transition: padding-bottom .2s ease;
      text-align: center;
      grid-area: title;
      display: flex;
      justify-self: center;
      align-self: flex-end;
    }

    p {
      padding-top: rem(26px);
      text-transform: uppercase;
      text-align: center;
      font-size: rem(10px);
      visibility: hidden;
      opacity: 0;
      transition: visibility .0s, opacity .2s linear;
      grid-area: desc;
      display: flex;
      justify-self: center;
      align-self: flex-start;
    }

    div {
      cursor: pointer;
      width: rem(150px);

      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: rem(80px) rem(72px) rem(90px);
      grid-template-areas:
        "title"
        "img"
        "desc";

      @include media-min($md) {
        grid-template-rows: rem(80px) rem(72px) rem(50px);
      }

      @include media-max($lg) {
        &>p {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }
      

      &:hover {
        &>h4 {
          text-decoration: underline;
          padding-bottom: rem(15px);
        }

        &>img {
          transform: scale(1.2);
        }

        &>p {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}