.productInfo {
    display: grid;
    position: relative;
    background-color: #33351e;
    max-height: rem(300px);
    width: 100%;
    color: white;
    border-bottom: 1px solid $borderColor;

    @include media-min($md){
        grid-template-columns: repeat(5, 1fr);
    }

    &__box {
        z-index: 10;
        min-height: rem(220px);
        position: absolute;
        width: 100%;
        top: -50%;
        display: grid;

        @include media-max($sm){
            min-height: rem(250px);
            width: 78%;
            top: -65%;
            display: flex;
            justify-self: center;
        }

        @include media-min($sm){
            left: rem(20px);
        }

        @include media-min($md){
            left: 0;
            grid-template-columns: repeat(5, 1fr);
        }

        img {
            transform: translateX(-50%);
            position: absolute;
            top: 0;
            grid-column: 2 / 2;
            z-index: 1;

            @include media-min($md) {
                height: rem(126px);
            }

            @include media-min($xl) {
                height: rem(100px);
            }
        }

        div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;

            background-color: #505331;
            grid-column: 2 / 4;
            width: initial;
            margin: 0;
            padding-left: 3rem;
            padding-bottom: 1rem;
        }

        div span {
            font-weight: 700;
            text-transform: uppercase;
            font-size: rem(18px);
            margin-bottom: rem(4px);

            @include media-max($sm){
                font-size: rem(14px);
                margin-bottom: rem(4px);
            }
        }

        div p {
            font-family: 'montserrat';
            font-size: rem(37px);
            font-weight: 700;
            line-height: rem(44px);
            text-transform: uppercase;

            @include media-max($sm){
                font-size: rem(24px);
                line-height: rem(30px);
                margin-bottom: rem(54px);
            }
        }
    }

    &__cnt {
        display: grid;
        position: relative;
        width: initial;
        min-height: rem(298px);
        margin: 0;
        color: white;

        @include media-min($md){
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
                "text text"
                "text text";
    
            grid-column: 2 / 4;
        }
    }

    &__arrow {
        display: none;

        @include media-min($md){
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: rem(100px);

            img {
                animation: mover 1s infinite alternate;
                height: rem(21px);
            }
        }
    }

    &__text {
        padding: 4rem 2.5rem 1rem 2.5rem;
        grid-area: text;

        @include media-min($sm){
            padding: 4rem 2.5rem 1rem 7.5rem;
        }

        @include media-min($md){
            padding: 6rem 1rem 1rem 3rem;
        }

        ul {
            list-style: none;
            padding: 0;
        }

        h3 {
            font-weight: 600;
        }
    }
}