// ------------------------------
// Variables
// ------------------------------
//$breakpoint-xsmall: 360px;
//$breakpoint-wrapper: 1310px;
//$wrapper: 1230px;
//$wrapper-medium: 800px;
//$wrapper-padding: 40px;
//$wrapper-padding-medium: 30px;
//$wrapper-padding-small: 15px;
//Font
$font-family: "Montserrat", sans-serif;
//Breakpoints
$xsm: 450px;
$sm: 640px;
$md: 960px;
$lg: 1200px;
$xl: 1600px;
$xxl: 3840px;
//Colors
$primary: #2a2c17;
$primaryLight: #505331;
$primaryDark: #2a2c17;
$primaryDarkest: #2a2c17;
$secondary: #e0581a;
$secondaryLight: #f16322;
$secondaryDark: #ab4617;
$white: #fff;
$black: #000;
$link: #505331;
$borderColor: #60614f;
$borderColorLight: #e2e3db;
$menuDark: #1f2010;
//Transitions
$t-color: color .3s ease-in-out;
$t-bottom: bottom .3s ease-in-out;
$t-bc: background-color .3s ease-in-out;
$t-bd-b: border-bottom .3s ease-in-out;
$t-bd-col: border-color .3s ease-in-out;
$t-left: left .3s ease-in-out;
$t-margin: margin .3s ease-in-out;
$t-opacity: opacity .3s ease-in-out;
$t-top: top .3s ease-in-out;
$t-trans: transform .3s ease-in-out;
$t-width: width .3s ease-in-out;

