#cookie-alert {
  z-index: 900;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
  background: none #33351e;
  color: #fff;
  font-size: 14px;
  padding: 6px 0;
  text-align: center;
}
#cookie-alert > p {
  margin: 0;
}

#cookie-alert a {
  text-decoration: underline;
}
